import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../adapters/accounts';
import { getToken } from '../../adapters/accounts/auth';
import { Button, Card, Input, Text, TextType, Variant } from '../../ComponentLibrary/src';
import { useMobile } from '../../ComponentLibrary/src/util/hooks';
import Page from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { UserContext, useUserContextValue } from '../../context/User';
import { Distributor } from '../../types';
import { isEmail } from '../../util';
import { PERMISSIONS } from '../../util/constants';
import { RoleNames } from '../../util/permissions';
import Access from '../Account/Access';
import RolesComponent from '../Account/Roles';

type Step = 'email' | 'access' | 'roles';

function Invite(): JSX.Element {
  const { user, userDiff, setUser, invite } = useContext(UserContext);
  const { t } = useTranslation();
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const steps: Step[] = ['email', 'roles', 'access'];
  const [step, setStep] = useState<Step>(steps[0]);
  const [distributorErrorMessage, setDistributorErrorMessage] = useState<string | undefined>('');
  const [revealEmailErrorMessage, setRevealEmailErrorMessage] = useState(false);
  const [isDistributor, setIsDistributor] = useState(user?.roles?.includes(RoleNames.distributor) ?? false);
  const [newUserDistributor, setNewUserDistributor] = useState<Distributor | null>();
  const decodedToken = Api.getUser();
  const isMobile = useMobile();

  useEffect(() => {
    if (!decodedToken) {
      getToken().then(() => {
        if (!hasPermissions([PERMISSIONS.qnergy.users.invite])) window.history.back();
      });
    } else if (!hasPermissions([PERMISSIONS.qnergy.users.invite])) window.history.back();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidateEmail = () => {
    if (revealEmailErrorMessage && !isEmail(user?.email)) {
      setRevealEmailErrorMessage(true);
    } else if (revealEmailErrorMessage) {
      setRevealEmailErrorMessage(false);
    }
  };

  const handleSendInvite = () => {
    if (isDistributor && !newUserDistributor) {
      setDistributorErrorMessage(t('roles:must_select_distributor'));
    } else if (window.confirm('This will send an email invitation to the email address provided. Are you sure?'))
      invite(isDistributor ? undefined : userDiff?.access, newUserDistributor?._id).then(() =>
        navigate(`/users/${user?.email}`),
      );
  };

  const handleChangeEmail = (email: string | number) => {
    setUser({
      ...user,
      email: email.toString(),
    });
  };

  const previousStep = useMemo((): Step | null => {
    // if there is a previous step, then get the preceding step to which the user has access
    const stepIndex = steps.indexOf(step);
    if (stepIndex === 0) return null;
    const previousStep = steps[stepIndex - 1];
    if (isDistributor && previousStep === 'access') return steps[stepIndex - 2];
    return previousStep;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const nextStep = useMemo((): Step | null => {
    // if there is a next step, then get the next step to which the user has access
    const stepIndex = steps.indexOf(step);
    if (stepIndex === steps.length - 1) return null;
    const nextStep = steps[stepIndex + 1];
    if (isDistributor && nextStep === 'access') return steps[stepIndex + 2];
    return nextStep;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isDistributor]);

  const handleNextStep = () => {
    if (step === 'email' && !isEmail(user?.email)) {
      return;
    } else if (step === 'roles' && !user?.roles?.length) {
      toast(t('roles:must_select_role'), { type: 'error' });
      return;
    }
    if (nextStep) setStep(nextStep);
  };

  const handlePreviousStep = () => {
    if (previousStep) setStep(previousStep);
  };

  const handleRemoveRole = (role: string) => {
    if (!user.roles) user.roles = [];
    const index = user.roles.indexOf(role);
    const roles = user.roles;
    if (index > -1) roles.splice(index, 1);
    setUser({
      ...user,
      roles,
    });
  };

  const handleSelectRole = (value: string | number) => {
    if (!user.roles) user.roles = [];
    setUser({
      ...user,
      roles: [...user.roles, value.toString()],
    });
  };

  let content = <></>;
  switch (step) {
    case 'access':
      content = <Access newUserMode />;
      break;
    case 'roles':
      content = (
        <RolesComponent
          newUserMode
          setIsDistributor={setIsDistributor}
          setNewUserDistributor={setNewUserDistributor}
          distributorErrorMessage={distributorErrorMessage}
          onSelectRole={handleSelectRole}
          onRemoveRole={handleRemoveRole}
          isDistributor={isDistributor}
          newUserDistributor={newUserDistributor}
        />
      );
      break;
    case 'email':
    default:
      content = (
        <Input
          label="Email"
          tooltip="Email address used for login and notifications"
          value={user?.email}
          onChangeValue={handleChangeEmail}
          revealErrorMessage={revealEmailErrorMessage}
          onBlur={handleValidateEmail}
          errorMessage={!isEmail(user?.email) ? 'must provide a valid email' : undefined}
          onPressEnter={handleNextStep}
          autoFocus
          data-pwid="invite-email"
        />
      );
  }

  return (
    <Page title="Invite" breadcrumbs={[{ text: 'Users', href: '/users' }, { text: 'Invite' }]}>
      <div className="flex flex-col items-center">
        <Card className="flex flex-col gap-2 w-full md:w-1/2 self-center min-h-lg" noPadding={isMobile}>
          {step !== 'email' && (
            <Text type={TextType.h4} overflow="">
              {step.charAt(0).toUpperCase() + step.slice(1)}
            </Text>
          )}
          {content}
          <div className={`w-full grid grid-cols-3 ${isMobile ? '' : 'gap-2'} mt-auto`}>
            {previousStep ? (
              <Button className="w-full" variant={Variant.secondary} onClick={handlePreviousStep}>
                Back
              </Button>
            ) : (
              <span></span>
            )}
            <span></span>
            {nextStep ? (
              <Button className="w-full" onClick={handleNextStep}>
                Next
              </Button>
            ) : (
              <Button className="w-full" onClick={handleSendInvite}>
                Send Invite
              </Button>
            )}
          </div>
        </Card>
      </div>
    </Page>
  );
}

export default function InviteContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <Invite />
    </UserContext.Provider>
  );
}
