import React, { ForwardedRef, forwardRef, useContext, useEffect, useState } from 'react';

import { Checkbox, HeroIcons, Input, Link, Text } from '../../ComponentLibrary/src';
import { UserContext } from '../../context/User';
import ValidatePasswords from '../ValidatePasswords';
import { validateTerms } from './util';

export interface Values {
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
  email: string;
}

interface Props {
  revealErrors: boolean;
  onChangeStage: (values: Values) => void;
  onNext: () => void;
  setSignupDisabled: (disabled: boolean) => void;
  visible?: boolean;
}

const Third = (
  { revealErrors, setSignupDisabled, onChangeStage, onNext, visible = true }: Props,
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { user, setUser } = useContext(UserContext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const termsErrorMessage = validateTerms(termsAccepted);

  useEffect(() => {
    onChangeStage({
      email: user.email,
      password: user.password,
      confirmPassword: user.password,
      termsAccepted,
    });
  }, [user.email, user.password, termsErrorMessage, termsAccepted, onChangeStage]);

  useEffect(() => {
    if (user.password != '' && user.password != undefined && termsAccepted) {
      setSignupDisabled(false);
    } else {
      setSignupDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.password, termsAccepted]);

  const onChangePassword = (value: string | number) => {
    setUser({
      ...user,
      password: value.toString(),
    });
  };

  const onChangeTerms = (value: boolean) => {
    setTermsAccepted(value);
  };

  const handleNext = () => {
    onNext();
  };

  const onValidPassword = (updatePassword: string | undefined) => {
    if (updatePassword && updatePassword != user.password) onChangePassword(updatePassword);
    else if (user.password != '') onChangePassword('');
  };

  return (
    <div className={`${visible ? '' : 'invisible'}`}>
      <Input
        leftIcon={HeroIcons.MailIcon}
        label="Email"
        placeholder="Email"
        onPressEnter={handleNext}
        value={user.email}
        disabled
        tooltip="This invitation will only work for this email address."
      />
      <ValidatePasswords ref={ref} onValidatePassword={onValidPassword} revealErrors={revealErrors} />
      <Checkbox
        onChangeValue={onChangeTerms}
        checked={termsAccepted}
        errorMessage={termsErrorMessage}
        revealErrorMessage={revealErrors}
        className="mt-4"
      >
        <div className="inline-flex">
          <Text inline wrap>
            By creating this account, I agree to the
            <Link href={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL}/terms`} newTab inline color="green">
              &nbsp;Terms of Use
            </Link>{' '}
            and
            <Link href={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL}/privacy`} newTab inline color="green">
              &nbsp;Privacy Policy
            </Link>
            .
          </Text>
        </div>
      </Checkbox>
    </div>
  );
};

export default forwardRef<HTMLInputElement, Props>(Third);
