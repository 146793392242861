export const screenSizeMediaQuery = {
  mobile: {
    query: '(max-width: 639px)',
  },
};

export const ACCOUNTS_API_URL = 'https://accountsapi.qnergy.com';
export const SMARTVIEW_API_URL = 'https://api.smartview.qnergy.com/v2';

export const PERMISSIONS = {
  qnergyView: {
    actionLogs: {
      add: 'qnergyview:actionLogs:add',
      read: 'qnergyview:actionLogs:read',
    },
    distributors: {
      add: 'qnergyview:distributors:add',
      delete: 'qnergyview:distributors:delete',
      update: 'qnergyview:distributors:update',
      read: 'qnergyview:distributors:read',
    },
    orgs: {
      add: 'qnergyview:orgs:add',
      delete: 'qnergyview:orgs:delete',
      update: 'qnergyview:orgs:update',
    },
    sites: {
      add: 'qnergyview:sites:add',
      delete: 'qnergyview:sites:delete',
      update: 'qnergyview:sites:update',
    },
    systems: {
      advancedRead: 'qnergyview:systems:advancedRead',
      update: 'qnergyview:systems:update',
      advancedUpdate: 'qnergyview:systems:advancedUpdate',
      updateProductAccess: 'qnergyview:systems:updateProductAccess',
      updateDistributor: 'qnergyview:systems:updateDistributor',
      updateOrg: 'qnergyview:systems:updateOrg',
      updateSite: 'qnergyview:systems:updateSite',
      updateShipDate: 'qnergyview:systems:updateShipDate',
      read: 'qnergyview:systems:read',
      isSubscriptionExempt: 'qnergyview:systems:isSubscriptionExempt',
    },
    othersActionLogs: {
      delete: 'qnergyview:othersActionLogs:delete',
      update: 'qnergyview:othersActionLogs:update',
    },
    ownActionLogs: {
      delete: 'qnergyview:ownActionLogs:delete',
      update: 'qnergyview:ownActionLogs:update',
    },
    developerOptions: {
      all: 'qnergyview:developerOptions:*',
    },
    faults: {
      update: 'qnergyview:faults:update',
      readAdvanced: 'qnergyview:faults:readAdvanced',
    },
    newFeatures: {
      read: 'qnergyview:newFeatures:read',
    },
    users: {
      updateAccess: 'qnergyview:users:updateAccess',
      updateDistributor: 'qnergyview:users:updateDistributor',
    },
  },
  qnergy: {
    users: {
      delete: 'qnergy:users:delete',
      read: 'qnergy:users:read',
      update: 'qnergy:users:update',
      updateRoles: 'qnergy:users:updateRoles',
      invite: 'qnergy:users:invite',
    },
  },
};
