import { Values as FirstValues } from './First';
import { Values as SecondValues } from './Second';
import { Values as ThirdValues } from './Third';

export enum Stages {
  name = 0,
  phone,
  password,
}

export type FinalValues = FirstValues & SecondValues & ThirdValues;

export enum Roles {
  iccpTech = 'iccpTech',
  iccpEngineer = 'iccpEngineer',
  automationTech = 'automationTech',
  automationEngineer = 'automationEngineer',
  siteOperator = 'siteOperator',
  supervisor = 'supervisor',
  superintendent = 'superintendent',
  manager = 'manager',
  engineer = 'engineer',
  designEngineer = 'designEngineer',
}
