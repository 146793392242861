import axios, { AxiosError, Method } from 'axios';
import jwt_decode from 'jwt-decode';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import i18n from '../../i18n/index';
import { DecodedToken, OnPermissionFail } from '../../types';
import { getApiUrl, getAuthorizationCode } from './auth';
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures#emulating_private_methods_with_closures
export default (function () {
  // private variables
  let token: string | undefined = undefined;

  // public variables
  let decodedToken: DecodedToken | undefined | null;
  let apiDown = false;
  let authorized = false;

  // const tokenChangedEvent = new TokenChangeEvent();
  const setToken = (newToken: string) => {
    token = newToken;
    decodedToken = token ? (jwt_decode(token) as DecodedToken) : undefined;
  };

  const request = async (options: {
    method?: Method;
    path: string;
    data?: unknown;
    auth?: boolean;
    params?: unknown;
    onFail?: OnPermissionFail;
  }): Promise<unknown> => {
    const { method, path, data, auth = true, params, onFail } = options;

    try {
      const headers: Record<string, unknown> = {
        Authorization: auth ? `Bearer ${token}` : undefined,
      };

      const apiUrl = getApiUrl();
      const response = await axios({
        method,
        url: `${apiUrl}/${path}`,
        withCredentials: true,
        headers,
        data,
        params,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
      });

      if (response.headers['authorization']) {
        setToken(response.headers['authorization']);
        // tell any subscribers that the token has changed (e.g. so they can re-render)
        window.dispatchEvent(new Event('DashboardTokenChanged'));
      }

      const responseData = response.data;

      if (response.headers['x-server-time'] && typeof responseData == 'object') {
        responseData.serverTimeMs = parseInt(response.headers['x-server-time']);
      }

      apiDown = false;
      return responseData;
    } catch (error: unknown) {
      if ((error as { message: string }).message === 'Network Error') {
        apiDown = true;
        toast('There is a problem with the server. Please contact an admin or try again later.', {
          type: 'error',
          toastId: 'NetworkError',
          autoClose: false,
        });
        // force a re-render
        window.dispatchEvent(new Event('DashboardTokenChanged'));
        return;
      } else {
        apiDown = false;
      }
      const err = error as {
        response: {
          data: {
            error: string;
          };
          status: number;
        };
      };
      if (err.response?.status === 401 && !authorized) {
        try {
          authorized = false;
          await getAuthorizationCode();
          return request(options);
        } catch (err) {
          toast('Unable to authenticate with Qnergy Dashboard API. Please try again.', { type: 'error' });
          return;
        }
      } else {
        authorized = true;
      }
      if ((err as AxiosError).response?.status === 403) {
        toast(i18n.t('Access Forbidden'), {
          type: toast.TYPE.ERROR,
          toastId: 'access',
          autoClose: false,
        });
        if (onFail) {
          return onFail(error as AxiosError);
        }
        if (window.location.pathname !== '/') window.location.href = '/';
      }
      throw err;
    }
  };

  return {
    isApiDown: () => apiDown,
    getUser: () => decodedToken,
    hasToken: () => !!token,
    clearToken: () => {
      token = undefined;
    },
    request,
    /** This allows react components to trigger renders when token changes */
    useRenderOnUserChange: () => {
      // You have to change state to get a re-render (e.g. for page access control)
      const [toggler, forceRender] = useState(false);

      useEffect(() => {
        const handleTokenChanged = () => {
          forceRender(!toggler);
        };
        window.addEventListener('DashboardTokenChanged', handleTokenChanged);

        return () => window.removeEventListener('DashboardTokenChanged', handleTokenChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    },
  };
})();
