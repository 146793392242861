import { CubeIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';

import { Text } from '../ComponentLibrary/src';

interface Props {
  system: string;
  disabled: boolean;
  onRemoveSystem: (id: string) => void;
}

export default function System({ system, disabled, onRemoveSystem }: Props): JSX.Element {
  return (
    <div className="flex flex-row items-center h-8 justify-between w-full gap-1 border-b">
      {!disabled && (
        <div className="w-6 h-6">
          <CubeIcon className="text-blue-800 w-6 h-6" />
        </div>
      )}
      <Text className="flex-1">{system}</Text>

      {!disabled && (
        <div className="w-6 h-6">
          <TrashIcon className="text-blue-800 w-6 h-6" onClick={() => onRemoveSystem(system)} />
        </div>
      )}
    </div>
  );
}
