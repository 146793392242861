import { createContext, useState } from 'react';

import Api from '../adapters/accounts';
import SmartviewApi from '../adapters/smartview';
import { loadApiUsed } from '../adapters/smartview/auth';

export interface AuthContextData {
  apiUsed?: 'default' | 'secondary';
  hasPermissions: (permissions: string | string[]) => boolean;
  toggleApiUsed: () => void;
  getApiUsed: () => 'default' | 'secondary' | undefined;
}

const authContextDefaultValue: AuthContextData = {
  apiUsed: 'default',
  hasPermissions: () => false,
  toggleApiUsed: () => undefined,
  getApiUsed: () => undefined,
};

export const useAuthContextValue = (): AuthContextData => {
  const [apiUsed, setApiUsed] = useState<'default' | 'secondary'>(loadApiUsed() as 'default' | 'secondary');

  const hasPermissions = (permissions: string | string[]): boolean => {
    const decodedToken = Api.getUser();
    const decodedSmartviewToken = SmartviewApi.getUser();
    if (!decodedToken && !decodedSmartviewToken) return false;

    const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];

    return permissionsArray.every(
      (permission) =>
        decodedToken?.permissions?.includes(permission) || decodedSmartviewToken?.permissions.includes(permission),
    );
  };

  const toggleApiUsed = (): void => {
    const newApiUsed = apiUsed === 'secondary' ? 'default' : 'secondary';
    localStorage.setItem('apiUsed', newApiUsed);
    setApiUsed(newApiUsed);
  };

  const getApiUsed = (): 'default' | 'secondary' => {
    return apiUsed;
  };

  return {
    apiUsed,
    hasPermissions,
    toggleApiUsed,
    getApiUsed,
  };
};

export const AuthContext = createContext<AuthContextData>(authContextDefaultValue);
