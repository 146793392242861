import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import packageJson from '../../package.json';
import { getAuthorizationCode } from '../adapters/accounts/auth';
import { Button, Card, Link as QnergyLink, Text } from '../ComponentLibrary/src';
import logo from '../ComponentLibrary/src/style/Qnergy_HOR_NoTag_RGB.svg';
import AuthWallBg from '../components/Bg';
import ValidatePasswords from '../components/ValidatePasswords';
import { UserContext, useUserContextValue } from '../context/User';
import { useQuery } from '../hooks';

const REDIRECT_SECS = 5;

const ResetPassword: FunctionComponent = () => {
  const { reset, validateResetToken } = useContext(UserContext);
  const query = useQuery();
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);
  const [passwordChanged, setPasswordChanged] = useState<boolean | null>(null);
  const [failureMessage, setFailureMessage] = useState('');
  const [redirectSecs, setRedirectSecs] = useState(REDIRECT_SECS);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [revealErrors, setRevealErrors] = useState(false);

  useEffect(() => {
    const token = query.get('resetToken');
    validateResetToken(token || '').then((isValid) => setTokenValid(isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (passwordChanged) {
      const timeoutId = setTimeout(() => {
        if (redirectSecs === 1) {
          getAuthorizationCode();
          clearTimeout(timeoutId);
        }
        setRedirectSecs(redirectSecs - 1);
      }, 1000);
    }
  });
  const handleSubmit = async (password: string) => {
    const token = query.get('resetToken');
    try {
      await reset(password, token || '');
      setPasswordChanged(true);
    } catch (err) {
      setPasswordChanged(false);
      setFailureMessage(
        (
          err as {
            response: {
              data: {
                error: string;
              };
            };
          }
        ).response?.data?.error,
      );
    }
  };

  const onValidPassword = (updatePassword: string | undefined) => {
    if (updatePassword != password) setPassword(updatePassword);
  };

  const onSubmitClick = () => {
    setRevealErrors(true);
    if (password) handleSubmit(password);
  };
  const handleClickLogin = () => {
    const loginUrl = query.get('loginUrl');
    if (loginUrl) {
      window.location.href = loginUrl;
    } else {
      getAuthorizationCode();
    }
  };

  let content;
  const validatingTokenContent = <Text className="text-center block">Validating reset token...</Text>;
  const invalidTokenContent = (
    <>
      <Text className="text-center block">Invalid or expired reset token. Please try again.</Text>
      <QnergyLink url="/reset">Reset Password</QnergyLink>
    </>
  );
  const getInputContent = (
    <>
      <ValidatePasswords onValidatePassword={onValidPassword} revealErrors={revealErrors} />
      <Button onClick={onSubmitClick} className="w-full" disabled={!password}>
        Change Password
      </Button>
    </>
  );
  const successContent = (
    <>
      <Text className="text-center block">Your password was successfully changed.</Text>
      <br />
      <Text overflow="" className="flex flex-col items-center text-center">
        This page will redirect in {redirectSecs} second{`${redirectSecs > 1 ? 's' : ''}`}.
      </Text>
    </>
  );

  const failedContent = (
    <Text className="text-center block">{failureMessage || 'There was an error changing your password.'}</Text>
  );

  if (passwordChanged) {
    content = successContent;
  } else if (tokenValid === false) {
    content = invalidTokenContent;
  } else if (tokenValid === true && passwordChanged === null) {
    content = getInputContent;
  } else if (passwordChanged === false) {
    content = failedContent;
  } else {
    content = validatingTokenContent;
  }

  return (
    <AuthWallBg>
      <Card
        noPadding
        className="inline-flex flex-col p-8 sm:p-12 lg:p-16 rounded-lg gap-y-12 sm:max-w-md max-h-full h-full sm:h-auto w-full items-center"
      >
        <img src={logo} className="w-2/3" />
        {content}
        <QnergyLink onClick={handleClickLogin}>Login</QnergyLink>
        <div className="flex flex-row justify-around w-full lg:p-4 items-center flex-wrap">
          <Text className="font-thin">{packageJson.version || 'ver?'}</Text>
          <Text className="font-thin">©{new Date().getFullYear()} Qnergy</Text>
          <QnergyLink url={`${process.env.REACT_APP_AUTH_FRONTEND_URL}/terms`}>Terms</QnergyLink>
        </div>
      </Card>
    </AuthWallBg>
  );
};

export default function ResetPasswordContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <ResetPassword />
    </UserContext.Provider>
  );
}
