import { isEmail, validateConfirmPassword, validatePassword } from '../../util';
import { Values as FirstValues } from './First';
import { FinalValues, Stages } from './index.types';
import { Values as SecondValues } from './Second';
import { Values as ThirdValues } from './Third';

export const validateFirstName = (firstName?: string): string | undefined => {
  if (!firstName) return 'please provide your first name';
};

export const validateLastName = (lastName?: string): string | undefined => {
  if (!lastName) return 'please provide your last name';
};

export const validateEmail = (email: string): string | undefined => {
  return !isEmail(email) ? 'invalid email address' : undefined;
};

export const validateTerms = (termsAccepted: boolean): string | undefined => {
  if (!termsAccepted) return 'please accept the terms of service';
};

export const validatePhoneNumber = (value: string) => {
  return /^[0-9.\-+ ()ext]*$/.test(value) ? undefined : 'please provide a valid phone number';
};

type StageValues = FirstValues | SecondValues | ThirdValues;

function isFirstValues(data: StageValues): data is FirstValues {
  return (<FirstValues>data).firstName !== undefined && (<FirstValues>data).lastName !== undefined;
}

function isSecondValues(data: StageValues): data is SecondValues {
  return (<SecondValues>data).phone !== undefined && (<SecondValues>data).mobile !== undefined;
}

function isThirdValues(data: StageValues): data is FinalValues {
  return (
    (<ThirdValues>data).email !== undefined &&
    (<ThirdValues>data).password !== undefined &&
    (<ThirdValues>data).confirmPassword !== undefined &&
    (<ThirdValues>data).termsAccepted !== undefined
  );
}

export const validateStage = (stage: Stages, data: StageValues): boolean => {
  switch (stage) {
    case Stages.name:
      return isFirstValues(data) && !validateFirstName(data.firstName) && !validateLastName(data.lastName);
    case Stages.phone:
      return isSecondValues(data) && !validatePhoneNumber(data.phone) && !validatePhoneNumber(data.mobile);
    case Stages.password:
      return (
        isThirdValues(data) &&
        !validateFirstName(data.firstName) &&
        !validateLastName(data.lastName) &&
        !validatePhoneNumber(data.phone) &&
        !validatePhoneNumber(data.mobile) &&
        !validatePassword(data.password) &&
        !validateConfirmPassword(data.password, data.confirmPassword) &&
        !validateTerms(data.termsAccepted)
      );
    default:
      return true;
  }
};
