import React, { ForwardedRef, forwardRef, useContext } from 'react';

import { HeroIcons, Input, InputType } from '../../ComponentLibrary/src';
import { UserContext } from '../../context/User';
import { validatePhoneNumber } from './util';

export interface Values {
  mobile: string;
  phone: string;
}

interface Props {
  onNext: () => void;
  visible?: boolean;
}

const Two = ({ onNext, visible = true }: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { user, setUser } = useContext(UserContext);

  const onChangeMobile = (value: string | number) => {
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        mobile: value.toString(),
      },
    });
  };

  const onChangePhone = (value: string | number) => {
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        phone: value.toString(),
      },
    });
  };

  return (
    <div className={`${visible ? '' : 'invisible'}`}>
      <Input
        ref={ref}
        onChangeValue={onChangePhone}
        leftIcon={HeroIcons.PhoneIcon}
        label="Phone"
        type={InputType.tel}
        placeholder="Phone"
        onPressEnter={onNext}
        value={user.profile.phone}
        errorMessage={validatePhoneNumber(user.profile.phone)}
        autoFocus
      />
      <Input
        onChangeValue={onChangeMobile}
        leftIcon={HeroIcons.DeviceMobileIcon}
        label="Mobile"
        type={InputType.tel}
        placeholder="Mobile"
        onPressEnter={onNext}
        value={user.profile.mobile}
        errorMessage={validatePhoneNumber(user.profile.mobile)}
      />
    </div>
  );
};

export default forwardRef<HTMLInputElement, Props>(Two);
