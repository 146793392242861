import React, { FunctionComponent } from 'react';

import { Text, TextType } from '../ComponentLibrary/src';
import logo from '../ComponentLibrary/src/style/Qnergy_HOR_NoTag_RGB.svg';
interface Props {
  title: string;
  color?: 'red';
}

const Header: FunctionComponent<Props> = ({ title, color }: Props) => {
  let colorClass = '';
  if (color === 'red') colorClass = ' text-red-500';
  return (
    <div className="flex flex-col items-center md:h-36 h-24">
      <img src={logo} alt="logo" className="md:w-56 sm:w-36 w-36" />
      <Text type={TextType.h3} className={`p-2 justify-center${colorClass}`} overrideColor={!!color}>
        {title}
      </Text>
    </div>
  );
};

export default Header;
