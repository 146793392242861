import React, { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';

import { HeroIcons, Input, InputType } from '../ComponentLibrary/src';
import { validateConfirmPassword, validatePassword } from '../util';

interface ValidatePasswordsProps {
  revealErrors: boolean;
  onValidatePassword: (validPassword: string | undefined) => void;
}

const ValidatePasswords = (
  { revealErrors, onValidatePassword }: ValidatePasswordsProps,
  ref?: ForwardedRef<HTMLInputElement>,
) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    onValidatePassword(
      !password ||
        !confirmPassword ||
        !!validatePassword(password) ||
        !!validateConfirmPassword(password, confirmPassword)
        ? undefined
        : password,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword]);

  const handleChangePassword = useCallback((value) => {
    setPassword(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeConfirmPassword = useCallback((value) => {
    setConfirmPassword(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Input
        ref={ref}
        className="w-full"
        onChangeValue={handleChangePassword}
        type={InputType.password}
        leftIcon={HeroIcons.MailIcon}
        label="Choose a Password"
        placeholder="Password"
        revealErrorMessage={revealErrors}
        errorMessage={validatePassword(password)}
        value={password}
      />
      <Input
        className="w-full"
        onChangeValue={handleChangeConfirmPassword}
        type={InputType.password}
        leftIcon={HeroIcons.MailIcon}
        label="Confirm Password"
        placeholder="Confirm Password"
        revealErrorMessage={revealErrors}
        errorMessage={validateConfirmPassword(password, confirmPassword)}
        value={confirmPassword}
      />
    </>
  );
};

export default forwardRef<HTMLInputElement, ValidatePasswordsProps>(ValidatePasswords);
