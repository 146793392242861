import { ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text, TextType } from '../../../ComponentLibrary/src';
import ExpansionPanel from '../../../components/ExpansionPanels';
import { Role } from '../../../types';
import { RoleNames } from '../../../util/permissions';
import PermissionList from './PermissionList';

interface SettableRolesProps {
  settableRoles: Role[];
  assignedRoles: string[] | null;
  handleChangeRoles: (checked: boolean, role: RoleNames) => void;
}

const SettableRoles: React.FunctionComponent<SettableRolesProps> = ({
  settableRoles,
  assignedRoles,
  handleChangeRoles,
}): JSX.Element => {
  const { t } = useTranslation('roles');

  return !assignedRoles ? (
    <div className="flex items-center justify-center my-2">
      <ExclamationIcon className="h-6 text-red-500" />
      <Text type={TextType.h5} textClassName="ml-2 text-red-500" overrideColor wrap>
        {t('no_edit')}
      </Text>
    </div>
  ) : (
    <ul>
      {settableRoles.map((role: Role) => {
        if (role.name != RoleNames.distributor) {
          return (
            <ExpansionPanel
              key={role.name}
              headerClassName="justify-between py-2 px-2 header-class"
              header={
                <>
                  <Text className="flex-1">{role.name}</Text>
                  <Checkbox
                    id={role.name}
                    checked={assignedRoles?.includes(role.name)}
                    onChangeValue={(checked: boolean) => handleChangeRoles(checked, role.name)}
                    hideErrorSection
                  />
                </>
              }
            >
              <PermissionList role={role} />
            </ExpansionPanel>
          );
        }
      })}
    </ul>
  );
};

export default SettableRoles;
