import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactTooltip from 'react-tooltip';

import Api from '../adapters/accounts';
import { deleteUser } from '../adapters/accounts/users';
import {
  Avatar,
  Button,
  Column,
  Filter,
  FilterKey,
  HeroIcons,
  KeyValue,
  Link,
  List,
  Mode,
  Pagination,
  StatusIndicator,
  SystemDisplayState,
  Table,
  Variant,
} from '../ComponentLibrary/src';
import Page from '../components/Page';
import { AuthContext } from '../context/Auth';
import { UserContext, useUserContextValue } from '../context/User';
import { UsersContext, useUsersContextValue } from '../context/Users';
import { usePaginationQuery } from '../hooks';
import { columnsToSortJson } from '../JsonApi/src';
import { UserState } from '../types';
import { loadOptionLabels } from '../util';
import { PERMISSIONS, screenSizeMediaQuery } from '../util/constants';
import { getDisplayTime, usePolling } from '../WebUtils';

const defaultCountPerPage = 20;

const defaultFilter: FilterKey[] = [
  {
    id: 'email',
    label: 'Email',
    selected: false,
  },
  {
    id: 'profile.name',
    label: 'Name',
    selected: false,
  },
  {
    id: 'created',
    label: 'Joined',
    selected: false,
    mode: Mode.dateRange,
  },
  {
    id: 'lastLogin',
    label: 'Last Login',
    selected: false,
    mode: Mode.dateRange,
  },
  {
    id: 'userState',
    label: 'User State',
    selected: false,
    searchable: false,
  },
  // {
  //   id: 'distributor',
  //   label: 'Distributor',
  //   selected: false,
  //   selectedValues: [],
  // },
];

const defaultColumns = [
  {
    id: 'userState',
    label: '',
    width: '3rem',
  },
  {
    id: 'email',
    label: 'Email',
    width: 2,
  },
  {
    id: 'profile.firstName',
    label: 'First Name',
    width: 1,
  },
  {
    id: 'profile.lastName',
    label: 'Last Name',
    width: 1,
  },
  // TODO: these are not ISODates in the db
  // {
  //   id: 'created',
  //   label: 'Joined',
  //   width: 1,
  // },
  {
    id: 'lastLogin',
    label: 'Last Login',
    width: 1,
  },
  // {
  //   id: 'distributor',
  //   label: 'Distributor',
  //   width: 1,
  // },
  {
    id: 'actions',
    label: 'Actions',
    width: '7rem',
    disableSort: true,
  },
];

const Users: FunctionComponent = () => {
  const isMobile = useMediaQuery(screenSizeMediaQuery.mobile);
  const [loading, setLoading] = useState(true);
  const [filterEditing, setFilterEditing] = useState(false);
  const [pageChangedManually, setPageChangedManually] = useState(false);
  const [columns, setColumns] = useState<Column[]>(defaultColumns);
  const {
    sort,
    setSort,
    order,
    setOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
    filter,
    setFilter,
    textSearch,
    setTextSearch,
  } = usePaginationQuery({
    sort: 'stats.state',
    order: 1,
    page: 1,
    pageSize: defaultCountPerPage,
    filter: defaultFilter,
    textSearch: '',
  });
  const {
    users,
    totalUsers,
    dailyActiveUsers,
    weeklyActiveUsers,
    monthlyActiveUsers,
    serverTime,
    getUsers,
    downloadUsers,
    fetchUsersFieldKeys,
  } = useContext(UsersContext);
  const { enableUser, disableUser, updateInvite } = useContext(UserContext);
  const { hasPermissions } = useContext(AuthContext);
  const isExtraLargeScreen = useMediaQuery({ query: '(min-width: 1536px)' });
  // const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isSmallScreen = useMediaQuery({ query: '(min-width: 640px)' });

  const columnsToDisplay = useMemo(
    () =>
      columns.filter((column) => {
        if (isExtraLargeScreen) {
          return column;
        } else {
          return column.id !== 'lastLogin' && column.id !== 'created' ? column : undefined;
        }
      }),
    [columns, isExtraLargeScreen],
  );

  const currentUser = Api.getUser();

  const syncUsers = async () => {
    getUsers({
      filterKeys: filter,
      countPerPage: pageSize,
      page,
      sort: columnsToSortJson(columns),
      textSearch,
      pageChangedManually,
    }).finally(() => {
      setLoading(false);
      setPageChangedManually(false);
    });
  };

  usePolling(() => {
    if (!filterEditing) syncUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pageSize, page, columns, sort, order, textSearch, filterEditing]);

  const handleChangeColumns = (newColumns: Column[]) => {
    const sortCol = newColumns.find((col) => !!col.sortDirection);
    if (sortCol) {
      setSort(sortCol.id.toString());
      if (sortCol.sortDirection === 'down') setOrder(-1);
      if (sortCol.sortDirection === 'up') setOrder(1);
    }
    setColumns(newColumns);
  };

  const handleChangeCountPerPage = (count: number) => {
    setPageSize(count);
  };

  const handleChangePageNumber = (page: number) => {
    setPageChangedManually(true);
    setPage(page);
  };

  const handleExport = () => {
    downloadUsers(filter, columnsToSortJson(columns), textSearch);
  };

  const handleDeleteUser = (email: string) => {
    if (window.confirm(`Are you sure you want to delete user with email ${email}? This cannot be undone.`)) {
      deleteUser(email).then(() => syncUsers());
      // TODO: delete from SV database too
    }
  };

  const headerRight = hasPermissions(PERMISSIONS.qnergy.users.read) ? (
    <div className="flex flex-row gap-8">
      <KeyValue label="Users" tooltip="Total number of user accounts" value={totalUsers.toString()} />
      <KeyValue
        label="Daily"
        tooltip="Total unique users with activity in the last day"
        value={dailyActiveUsers.toString()}
      />
      <KeyValue
        label="Weekly"
        tooltip="Total unique users with activity in the last week"
        value={weeklyActiveUsers.toString()}
      />
      <KeyValue
        label="Monthly"
        tooltip="Total unique users with activity in the last month"
        value={monthlyActiveUsers.toString()}
      />
    </div>
  ) : undefined;

  const actions = (
    <div className="flex flex-row gap-2">
      {hasPermissions(PERMISSIONS.qnergy.users.invite) && (
        <Link href="/invite">
          <Button
            size={isMobile ? 'small' : 'normal'}
            icon={HeroIcons.MailIcon}
            className={isMobile ? '' : 'w-max'}
            variant={Variant.secondaryFilled}
          >
            Invite User
          </Button>
        </Link>
      )}
      <Button
        size={isMobile ? 'small' : 'normal'}
        icon={HeroIcons.DownloadIcon}
        className={isMobile ? 'hidden' : 'w-max'}
        variant={Variant.secondaryFilled}
        onClick={handleExport}
      >
        Export
      </Button>
    </div>
  );

  return (
    <Page title="Users" headerRight={isMobile ? undefined : headerRight}>
      <div className="flex items-center">
        <Filter
          filter={filter}
          onChangeFilter={setFilter}
          loadOptions={(key: string, searchTerm?: string, onlySelected?: boolean) =>
            fetchUsersFieldKeys({ filterKeys: filter, key, searchTerm, onlySelected })
          }
          loadOptionLabels={loadOptionLabels}
          onChangeFilterEditing={setFilterEditing}
          onChangeTextSearch={setTextSearch}
          textSearch={textSearch}
          autofocusSearch
        />
        {actions}
      </div>
      <div className="overflow-y-scroll">
        {isMobile ? (
          <>
            <List
              className="p-2 mb-10"
              items={users.map((user) => {
                let actionButtonText, handleAction;
                if (user.userState === UserState.active) {
                  actionButtonText = 'Disable';
                  handleAction = () => {
                    if (!window.confirm('Are you sure you want to disable this user?')) return;
                    disableUser(user.email).then(() => syncUsers());
                  };
                } else if (user.userState === UserState.disabled) {
                  actionButtonText = 'Enable';
                  handleAction = () => {
                    if (!window.confirm('Are you sure you want to enable this user?')) return;
                    enableUser(user.email).then(() => syncUsers());
                  };
                } else if (user.userState === UserState.pending) {
                  actionButtonText = 'Resend Invitation';
                  handleAction = () => {
                    if (
                      !window.confirm('This will send an email invitation to the email address provided. Are you sure?')
                    )
                      return;
                    updateInvite(user?.email);
                  };
                }
                return {
                  thumbnail: (
                    <Avatar
                      uniqueId={user?.email}
                      className="w-full max-w-lg"
                      initials={`${user?.profile?.firstName?.charAt(0).toUpperCase() ?? ''}${
                        user?.profile?.lastName?.charAt(0).toUpperCase() ?? ''
                      }`}
                    />
                  ),
                  // indicator
                  title: `${user.profile?.firstName ?? '(Unknown)'}${
                    user.profile?.lastName ? ' ' + user.profile?.lastName : ''
                  }`,
                  subtitle: `[${user.userState}]`,
                  description: user.email,
                  properties: (
                    <Button size="small" key={user._id} onClick={handleAction}>
                      {actionButtonText}
                    </Button>
                  ),
                  linkUrl: `/users/${encodeURIComponent(user.email)}`,
                  className: 'bg-white',
                };
              })}
            />
            <Pagination
              className={'absolute bottom-0 left-0 right-0 z-900'}
              pageNumber={page}
              countPerPage={pageSize}
              totalCount={totalUsers || 0}
              onChangePageNumber={handleChangePageNumber}
              mobile
            />
          </>
        ) : (
          <Table
            columns={columnsToDisplay}
            rows={users?.map((user) => {
              let tooltip = '';
              let StatusActionButton = HeroIconsSolid[HeroIcons.QuestionMarkCircleIcon as keyof typeof HeroIconsSolid];
              let handleAction;
              if (user.userState === UserState.active) {
                StatusActionButton = HeroIconsSolid[HeroIcons.BanIcon as keyof typeof HeroIconsSolid];
                tooltip = `Disable user ${user.email}`;
                handleAction = () => {
                  if (!window.confirm('Are you sure you want to disable this user?')) return;
                  disableUser(user.email)
                    .then(() => syncUsers())
                    .then(() => ReactTooltip.rebuild());
                };
              } else if (user.userState === UserState.disabled) {
                tooltip = `Enable user ${user.email}`;
                StatusActionButton = HeroIconsSolid[HeroIcons.LockOpenIcon as keyof typeof HeroIconsSolid];
                handleAction = () => {
                  if (!window.confirm('Are you sure you want to enable this user?')) return;
                  enableUser(user.email)
                    .then(() => syncUsers())
                    .then(() => ReactTooltip.rebuild());
                };
              } else if (user.userState === UserState.pending) {
                tooltip = `Re-invite user ${user.email}`;
                StatusActionButton = HeroIconsSolid[HeroIcons.MailIcon as keyof typeof HeroIconsSolid];
                handleAction = () => {
                  if (
                    !window.confirm('This will send an email invitation to the email address provided. Are you sure?')
                  )
                    return;
                  updateInvite(user?.email);
                };
              }

              let userStateIndicator = SystemDisplayState.unknown;
              if (user.userState === 'active') {
                userStateIndicator = SystemDisplayState.enabled;
              } else if (user.userState === 'pending') {
                userStateIndicator = SystemDisplayState.warning;
              } else if (user.userState === 'disabled') {
                userStateIndicator = SystemDisplayState.faulted;
              }

              return {
                ...user,
                id: user._id ?? Math.floor(Math.random() * 100001),
                firstName: user.profile?.firstName,
                lastName: user.profile?.lastName,
                created: user.created ? getDisplayTime(user.created as string, serverTime) : '',
                lastLogin: user.lastLogin ? getDisplayTime(user.lastLogin as string, serverTime) : '',
                email: (
                  <Link
                    url={`/users/${user.email === currentUser?.email ? 'current' : encodeURIComponent(user.email)}`}
                  >
                    {user.email}
                  </Link>
                ),
                actions: (
                  <div className="flex flex-row gap-2">
                    <StatusActionButton
                      className="cursor-pointer w-6 h-6 text-blue-800"
                      onClick={handleAction}
                      data-tip={tooltip}
                    />
                    {hasPermissions(PERMISSIONS.qnergy.users.delete) && (
                      <HeroIconsSolid.TrashIcon
                        className="cursor-pointer w-6 h-6 text-red-800"
                        onClick={() => handleDeleteUser(user.email)}
                        data-tip={`Delete user ${user.email}`}
                      />
                    )}
                  </div>
                ),
                userState: (
                  <StatusIndicator
                    state={userStateIndicator}
                    tooltip={`The user state is ${user.userState ?? 'unknown'}`}
                  />
                ),
              };
            })}
            pageNumber={page}
            countPerPage={pageSize}
            totalCount={totalUsers}
            onChangeCountPerPage={handleChangeCountPerPage}
            onChangePageNumber={handleChangePageNumber}
            onChangeColumns={handleChangeColumns}
            loading={loading}
          />
        )}
      </div>
    </Page>
  );
};

export default function UsersPasswordContainer(): JSX.Element {
  const userContextValue = useUserContextValue();
  const usersContextValue = useUsersContextValue();

  return (
    <UsersContext.Provider value={usersContextValue}>
      <UserContext.Provider value={userContextValue}>
        <Users />
      </UserContext.Provider>
    </UsersContext.Provider>
  );
}
