import React, { FunctionComponent, ReactNode } from 'react';

import colors from '../ComponentLibrary/src/style/colors';

interface Props {
  children: ReactNode;
}

const Container: FunctionComponent<Props> = ({ children }: Props) => {
  /* background by SVGBackgrounds.com */
  // https://www.svgbackgrounds.com/?ref=lapaninja#subtle-prism
  const ovals = {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='26' viewBox='0 0 32 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0v3.994C14 7.864 10.858 11 7 11c-3.866 0-7-3.138-7-7.006V0h2v4.005C2 6.765 4.24 9 7 9c2.756 0 5-2.236 5-4.995V0h2zm0 26v-5.994C14 16.138 10.866 13 7 13c-3.858 0-7 3.137-7 7.006V26h2v-6.005C2 17.235 4.244 15 7 15c2.76 0 5 2.236 5 4.995V26h2zm2-18.994C16 3.136 19.142 0 23 0c3.866 0 7 3.138 7 7.006v9.988C30 20.864 26.858 24 23 24c-3.866 0-7-3.138-7-7.006V7.006zm2-.01C18 4.235 20.244 2 23 2c2.76 0 5 2.236 5 4.995v10.01C28 19.765 25.756 22 23 22c-2.76 0-5-2.236-5-4.995V6.995z' fill='%23ffffff' fill-opacity='0.55' fill-rule='evenodd'/%3E%3C/svg%3E"), linear-gradient(to bottom, ${colors.emerald['500']}, ${colors.emerald['300']})`,
    backgroundSize: 600,
  };

  return (
    <div className="flex flex-row h-full w-full sm:w-8/12 sm:h-5/6 sm:9/12 md:w-8/12 lg:w-7/12 xl:w-9/12 2xl:w-[1150px]">
      <div
        className={`flex-1 rounded-tl-xl rounded-bl-xl flex-col pt-24 gap-8 bg-center justify-center text-center text-blue-800 hidden xl:flex`}
        style={{
          ...ovals,
        }}
      ></div>
      <div className="flex-1 bg-white h-full flex flex-col p-4 md:p-8 lg:p-12 xl:p-14 items-center sm:rounded-xl xl:rounded-tl-none xl:rounded-bl-none overflow-hidden">
        {children}
      </div>
    </div>
  );
};

export default Container;
