import { ACCOUNTS_API_URL } from '../../util/constants';
import Api from './index';

export const getAuthorizationCode = (): void => {
  let redirectUrl: string | undefined = window.location.href;
  if (redirectUrl.includes('resetPassword')) redirectUrl = undefined;
  window.location.href = `${process.env.REACT_APP_ACCOUNTS_API_URL || ACCOUNTS_API_URL}/login?redirectUrl=${
    redirectUrl ? redirectUrl : process.env.REACT_APP_SMARTVIEW_FRONTEND_URL
  }`;
};

export async function getToken(): Promise<void> {
  return Api.request({
    method: 'get',
    path: `token`,
    auth: false,
  }) as Promise<void>;
}

export async function logout(): Promise<void> {
  const url = `${process.env.REACT_APP_ACCOUNTS_API_URL || ACCOUNTS_API_URL}`;
  localStorage.clear();
  window.location.href = `${url}/logout`;
}
