import 'react-loading-skeleton/dist/skeleton.css';
import '../style/index.css';

import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Input } from '../Input';
import { Border } from '../Input/index.types';
import { Label } from '../Label';
import { Link } from '../Link';
import colors from '../style/colors';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { KeyValueProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const KeyValue: React.FC<KeyValueProps> = ({
  placeholder,
  label,
  value,
  tooltip,
  valueTooltip,
  dark,
  windowMode,
  border = Border.none,
  loading = false,
  editable,
  className,
  valueColor,
  inverted,
  editOnMount,
  ellipsis,
  wrap,
  align,
  valueLink,
  onSave,
  onCancelEdit,
  children,
  'data-pwid': dataPwid = 'key-value',
}: KeyValueProps) => {
  const [_value, setValue] = useState(value ?? '');
  const [editing, setEditing] = useState(editable && editOnMount);

  useEffect(() => {
    setValue(value ?? '');
  }, [value]);

  let borderClass = '';

  if (border === Border.bottom) {
    borderClass = ` border-b border-blue-800`;
  } else if (border === Border.none) {
    borderClass = ` border-0`;
  } else if (border === Border.outline) {
    borderClass = ` border border-blue-800`;
  }

  const textColorClass = valueColor || 'text-blue-800';

  const content =
    editing || placeholder ? (
      <Input
        placeholder={placeholder}
        transparent={!inverted}
        border={inverted ? Border.none : Border.bottom}
        hideErrorSection
        rightIcon={
          <div className="flex flex-row gap-2">
            <XIcon
              className={`w-6 h-6 text-gray-400 clickable-text-opacity cursor-pointer`}
              onClick={(e) => {
                setEditing(false);
                setValue(value ?? '');
                if (onCancelEdit) onCancelEdit();
                e.stopPropagation();
              }}
              data-pwid={`${dataPwid}-cancel`}
            />
            <CheckIcon
              className="w-6 h-6 text-emerald-500 clickable-text-opacity cursor-pointer"
              onClick={(e) => {
                setEditing(false);
                if (onSave) onSave(_value);
                e.stopPropagation();
              }}
              data-pwid={`${dataPwid}-save`}
            />
          </div>
        }
        value={_value}
        onChange={(e) => {
          if (!editing && e.target.value !== value) {
            setEditing(true);
          } else if (editing && e.target.value === value) {
            setEditing(false);
          }
          setValue(e.target.value);
        }}
        data-pwid={`${dataPwid}-value-input`}
      />
    ) : (
      <div className={`flex flex-row gap-2 items-center${ellipsis ? ' overflow-hidden' : ''}`}>
        {valueLink ? (
          <Link href={valueLink} data-pwid={`link-${value}`}>
            {value}
          </Link>
        ) : (
          <Text
            type={TextType.custom}
            overrideColor
            className={`text-base flex-1 ${textColorClass}`}
            overflow={ellipsis ? 'ellipsis' : ''}
            wrap={wrap}
            align={align}
          >
            {value ?? ''}
          </Text>
        )}
        {editable && (
          <PencilIcon
            className={`w-6 h-6 ${
              inverted ? 'text-white' : 'text-gray-400'
            } clickable-text-opacity cursor-pointer edit-icon`}
            onClick={(e) => {
              setEditing(true);
              e.stopPropagation();
            }}
            data-pwid={value ? `edit-${value}` : 'edit'}
          />
        )}
      </div>
    );

  return (
    <div
      className={`flex flex-row items-center justify-between gap-3 sm:py-1 key-value${borderClass}${appendClassProps(
        className,
      )}`}
      data-pwid={label || ''}
    >
      <Label tooltip={tooltip} dark={dark} windowMode={windowMode} className={`block ${!label ? ' hidden' : ''}`}>
        {label}
      </Label>
      {loading ? (
        <div className="flex-1">
          <SkeletonTheme baseColor={colors.gray[300]} highlightColor={colors.gray[200]}>
            <Skeleton />
          </SkeletonTheme>
        </div>
      ) : children ? (
        children
      ) : (
        <div data-tip={valueTooltip} data-pwid={label ? `${label}-content` : 'content'}>
          {content}
        </div>
      )}
    </div>
  );
};
