import { PlusIcon } from '@heroicons/react/outline';
import { LocationMarkerIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

import { fetchSystemsForSite } from '../adapters/smartview/endpoints';
import { Accordion, AsyncSelect, Checkbox, ReactSelectOption, Text } from '../ComponentLibrary/src';
import { Site as ISite, System as ISystem } from '../types';
import System from './System';

interface Props {
  site: ISite;
  disabled: boolean;
  onAddSystemToSite: (sysId: string, siteId: string) => void;
  onRemoveSystem: (system: string, siteId: string) => void;
  onRemoveSite: (siteId: string) => void;
  onChangeAllSystems: (allSystems: boolean, siteId: string) => void;
}

export default function Site({
  site,
  disabled,
  onAddSystemToSite,
  onRemoveSystem,
  onRemoveSite,
  onChangeAllSystems,
}: Props): JSX.Element {
  const [open, setOpen] = useState(true);

  const handleRemoveSite = () => {
    onRemoveSite(site._id);
  };

  const handleRemoveSystem = (system: string) => {
    onRemoveSystem(system, site._id);
  };

  const handleAddSystem = (system: ReactSelectOption | null) => {
    if (system) onAddSystemToSite(system?.value as string, site._id);
  };

  const handleChangeAllSystems = (allSystems: boolean) => {
    onChangeAllSystems(allSystems, site._id);
  };

  const handleLoadOptions = (textSearch: string) => {
    return fetchSystemsForSite(
      {
        siteId: site._id,
      },
      textSearch,
    ).then((systems: ISystem[]) => {
      return systems.map((system) => ({
        label: system.label ?? system.sysId,
        value: system.sysId,
      }));
    });
  };

  const rowContent = (
    <>
      <LocationMarkerIcon className="text-blue-800 w-6 h-6" />
      <Text className="flex-1 overflow-hidden">{site.name}</Text>
      <div className="flex gap-2">
        <Checkbox
          checked={site.allSystems ?? false}
          onChangeValue={handleChangeAllSystems}
          hideErrorSection
          label="All"
          disabled={disabled}
        />
        {!disabled && <TrashIcon className="text-blue-800 w-6 h-6 cursor-pointer" onClick={handleRemoveSite} />}
      </div>
    </>
  );

  if (site.allSystems) {
    return <div className="flex flex-row items-center h-8 gap-1 border-b pl-7">{rowContent}</div>;
  }

  return (
    <Accordion
      headerClassName="h-8 gap-1 border-b"
      key={site._id}
      header={<div className="flex flex-row items-center h-8 gap-1 w-full">{rowContent}</div>}
      open={open}
      onChange={setOpen}
    >
      <div className="flex flex-col gap-2 pt-2 pl-7">
        {!site.allSystems &&
          site.systems?.map((system: string) => (
            <System key={system} system={system} onRemoveSystem={handleRemoveSystem} disabled={disabled} />
          ))}
        {!disabled && (
          <AsyncSelect
            className="w-full sm:w-1/2"
            onLoadOptions={handleLoadOptions}
            filterOption={(option) => !site?.systems?.some((sysId) => sysId === option.value)}
            onChange={handleAddSystem}
            hideErrorSection
            placeholder="System"
            menuPlacement="bottom"
            disabled={disabled}
            clearable
            singleSelectMode
            icon={<PlusIcon className="h-6 text-gray-400" />}
          />
        )}
      </div>
    </Accordion>
  );
}
