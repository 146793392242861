import { OfficeBuildingIcon, PlusIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

import { fetchSitesForOrg } from '../adapters/smartview/endpoints';
import { Accordion, AsyncSelect, Checkbox, ReactSelectOption, Text } from '../ComponentLibrary/src';
import { OrganizationAccess, Site as ISite } from '../types';
import Site from './Site';

interface Props {
  org: OrganizationAccess;
  disabled: boolean;
  onAddSiteToOrg: (
    site: {
      _id: string;
      name: string;
    },
    orgId: string,
  ) => void;
  onRemoveOrg: (orgId: string) => void;
  onRemoveSite: (siteId: string, orgId: string) => void;
  onRemoveSystem: (sysId: string, orgId: string, siteId: string) => void;
  onAddSystem: (sysId: string, orgId: string, siteId: string) => void;
  onChangeAllSites: (orgId: string, allSites: boolean) => void;
  onChangeAllSystems: (orgId: string, siteId: string, allSystems: boolean) => void;
}

export default function Organization({
  org,
  disabled,
  onAddSiteToOrg,
  onRemoveOrg,
  onRemoveSite,
  onRemoveSystem,
  onAddSystem,
  onChangeAllSites,
  onChangeAllSystems,
}: Props): JSX.Element {
  const [open, setOpen] = useState(true);

  const handleAddSite = (option: ReactSelectOption | null) => {
    if (option && !org.sites.some((site) => site._id === option.value))
      onAddSiteToOrg(
        {
          _id: option.value as string,
          name: option.label as string,
        },
        org._id,
      );
  };

  const handleRemoveOrg = () => {
    onRemoveOrg(org._id);
  };

  const handleRemoveSystem = (sysId: string, siteId: string) => {
    onRemoveSystem(sysId, org._id, siteId);
  };

  const handleAddSystem = (sysId: string, siteId: string) => {
    onAddSystem(sysId, org._id, siteId);
  };

  const handleRemoveSite = (siteId: string) => {
    onRemoveSite(siteId, org._id);
  };

  const handleChangeAllSites = (allSites: boolean) => {
    onChangeAllSites(org._id, allSites);
  };

  const handleChangeAllSystems = (allSystems: boolean, siteId: string) => {
    onChangeAllSystems(org._id, siteId, allSystems);
  };

  const handleLoadOptions = async (searchTerm?: string): Promise<ReactSelectOption[]> => {
    const existingSites = org?.sites?.map((site) => site._id);
    return fetchSitesForOrg({
      filter: {
        orgId: org._id,
        _id:
          existingSites.length === 1
            ? {
                $ne: existingSites,
              }
            : {
                $nin: existingSites,
              },
      },
      searchTerm,
    }).then((sites: ISite[]) => {
      return sites.map((site) => ({
        label: site.label ?? site.name,
        value: site._id,
      }));
    });
  };

  const rowContent = (
    <>
      <OfficeBuildingIcon className="text-blue-800 h-6 w-6" />
      <Text className="flex-1 overflow-hidden">{org.name}</Text>
      <div className="flex gap-2">
        <Checkbox
          checked={org.allSites ?? false}
          onChangeValue={handleChangeAllSites}
          hideErrorSection
          label="All"
          disabled={disabled}
        />
        {!disabled && (
          <div className="w-6 h-6">
            <TrashIcon className="text-blue-800 w-6 h-6 cursor-pointer" onClick={handleRemoveOrg} />
          </div>
        )}
      </div>
    </>
  );

  if (org.allSites) {
    return <div className="flex flex-row items-center h-8 gap-1 border-b pl-7">{rowContent}</div>;
  }

  return (
    <Accordion headerClassName="h-8 gap-1 border-b" header={rowContent} open={open} onChange={setOpen}>
      <div className="flex flex-col gap-2 pt-2">
        {!org.allSites &&
          org.sites?.map((site: ISite) => {
            return (
              <Site
                key={site._id}
                site={site}
                onRemoveSystem={handleRemoveSystem}
                onRemoveSite={handleRemoveSite}
                onAddSystemToSite={handleAddSystem}
                onChangeAllSystems={handleChangeAllSystems}
                disabled={disabled}
              />
            );
          })}
        {!disabled && (
          <AsyncSelect
            className="w-full sm:w-1/2"
            onLoadOptions={handleLoadOptions}
            filterOption={(option) => !org?.sites?.some((site) => site._id === option.value)}
            onChange={handleAddSite}
            hideErrorSection
            placeholder="Site Name"
            menuPlacement="bottom"
            singleSelectMode
            clearable
            icon={<PlusIcon className="h-6 text-gray-400" />}
          />
        )}
      </div>
    </Accordion>
  );
}
