import React, { FunctionComponent, ReactNode } from 'react';

import { appendClassProps } from '../util';

interface Props {
  children: ReactNode;
  className?: string | string[];
}

const Content: FunctionComponent<Props> = ({ children, className }: Props) => {
  return <div className={`w-full flex-1 flex flex-col justify-start${appendClassProps(className)}`}>{children}</div>;
};

export default Content;
