import React, { FunctionComponent, useContext, useState } from 'react';

import packageJson from '../../package.json';
import { getAuthorizationCode } from '../adapters/accounts/auth';
import { Button, Card, HeroIcons, Input, Link, Text, TextType } from '../ComponentLibrary/src';
import logo from '../ComponentLibrary/src/style/Qnergy_HOR_NoTag_RGB.svg';
import AuthWallBg from '../components/Bg';
import { UserContext, useUserContextValue } from '../context/User';
import { useQuery } from '../hooks';
import { isEmail } from '../util';

const ForgotPassword: FunctionComponent = () => {
  const query = useQuery();
  const { requestReset } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [formChecked, setFormChecked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const emailErrorMessage = !isEmail(email) ? 'invalid email address' : undefined;

  const handleChangeEmail = (value: string | number) => {
    setEmail(value.toString());
    setFormChecked(false);
  };

  const handleSubmit = async () => {
    setFormChecked(true);
    if (!emailErrorMessage) {
      await requestReset(email);
      setEmailSent(true);
    }
  };

  const handleClickLogin = () => {
    const loginUrl = query.get('loginUrl');
    if (loginUrl) {
      window.location.href = loginUrl;
    } else {
      getAuthorizationCode();
    }
  };

  return (
    <AuthWallBg>
      <Card
        noPadding
        className="inline-flex flex-col p-8 sm:p-12 lg:p-16 rounded-lg gap-y-12 sm:max-w-md max-h-full h-full sm:h-auto w-full justify-between items-center"
      >
        <img src={logo} className="w-2/3" />
        <div className="w-full flex flex-col gap-4">
          <Text type={TextType.h4} className="justify-center w-full">
            Forgot Password
          </Text>
          <Text wrap align="center">
            Enter your email address to receive a password reset link.
          </Text>
        </div>
        {emailSent ? (
          <Text className="text-center w-full" wrap>
            An email was sent with instructions to reset your password.
          </Text>
        ) : (
          <div className="flex flex-col w-full items-center">
            <Input
              className="w-full"
              onChangeValue={handleChangeEmail}
              leftIcon={HeroIcons.MailIcon}
              placeholder="Email"
              errorMessage={emailErrorMessage}
              revealErrorMessage={formChecked}
              onPressEnter={handleSubmit}
            />
            <div className="flex flex-col items-center gap-4 w-full">
              <Button onClick={handleSubmit} className="w-full" containerClassName="w-full" size="large">
                Send Reset Link
              </Button>
              <Link onClick={handleClickLogin}>Return to Login</Link>
            </div>
          </div>
        )}
        <div className="flex flex-row justify-around w-full items-center flex-wrap">
          <Text className="font-thin">{packageJson.version || 'ver?'}</Text>
          <Text className="font-thin">©{new Date().getFullYear()} Qnergy</Text>
          <Link url={process.env.REACT_APP_SMARTVIEW_FRONTEND_URL + '/terms'}>Terms</Link>
          <Link url={process.env.REACT_APP_SMARTVIEW_FRONTEND_URL + '/privacy'}>Privacy</Link>
        </div>
      </Card>
    </AuthWallBg>
  );
};

export default function ResetContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <ForgotPassword />
    </UserContext.Provider>
  );
}
