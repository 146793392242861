import { FilterKey } from '../ComponentLibrary/src';

/**
 * Returns whether the string conforms to the RFC 5322 Official Standard
 * @param candidate
 * @returns {boolean}
 */
export const isEmail = (candidate: string): boolean => {
  // eslint-disable-next-line no-control-regex
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    candidate,
  );
};

export const appendClassProps = (className?: string | string[]): string => {
  if (Array.isArray(className)) {
    return className.reduce((final: string, name: string) => {
      if (name) {
        final = final + ' ' + name;
      }
      return final;
    }, '');
  }
  return className ? ' ' + className : '';
};

export const PASSWORD_RULES = {
  // At least one cap and one lowercase letter and one number or symbol.
  pattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])',
  minLength: 8,
};

export const validatePassword = (password: string): string | undefined => {
  const regex = new RegExp(PASSWORD_RULES.pattern);
  if (!password) return 'please provide a password';
  if (!regex.test(password))
    return 'Password must contain at least one capital letter, one lowercase letter and one number or symbol.';
  if (password.length < PASSWORD_RULES.minLength) return 'password must have at least 8 characters';
};

export const validateConfirmPassword = (password: string, confirmPassword: string): string | undefined => {
  return password !== confirmPassword ? 'passwords do not match' : undefined;
};

export async function loadOptionLabels(filter: FilterKey[]): Promise<FilterKey[]> {
  const staticLabels = ['email', 'profile.name', 'userState'];
  // and populate the labels of the first selected option

  const labelsToFetch: Record<string, string[]> = {};
  for (const filterKey of filter) {
    if (!filterKey.selectedValues?.length) continue;

    if (staticLabels.includes(filterKey.id as string)) {
      filterKey.selectedValues = filterKey.selectedValues.map((value) => {
        if (filterKey.id === 'userState') {
          value.label =
            (value.id?.toString() ?? '').charAt(0).toUpperCase() + (value.id?.toString() ?? '(None)').slice(1);
        } else {
          value.label = value.id?.toString() ?? '(None)';
        }
        return value;
      });
    } else {
      labelsToFetch[filterKey.id?.toString() ?? ''] = filterKey.selectedValues.map(
        (value) => value.id?.toString() ?? '(None)',
      );
    }
  }
  return filter;
}
