import React, { useState } from 'react';

import { Accordion } from '../ComponentLibrary/src/Accordion';
import { AccordionProps } from '../ComponentLibrary/src/Accordion/index.types';
import { useMobile } from '../ComponentLibrary/src/util/hooks';

const ExpansionPanel: React.FunctionComponent<AccordionProps> = (props) => {
  const [open, setOpen] = useState(false);
  // Used mobile hook to match the accordion padding left in components lib
  const isMobile = useMobile();

  return (
    <li className={`expansion-panel block mt-4 bg-white shadow-md ${open && 'open'}`}>
      <Accordion {...props} open={open} onChange={setOpen}>
        <div className={`${isMobile ? 'pr-2' : 'pr-6'} mt-4`}>{props.children}</div>
      </Accordion>
    </li>
  );
};

export default ExpansionPanel;
