import './permission_list.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextType } from '../../../ComponentLibrary/src';
import { Permission, Role } from '../../../types';

interface PermissionListProps {
  role: Role;
}

const PermissionList: React.FunctionComponent<PermissionListProps> = ({ role }): JSX.Element => {
  const { t } = useTranslation('roles');
  const getAppArray = ({ accounts, dashboard }: Role): [string, Permission[] | null][] => {
    return Object.entries({ Accounts: accounts, Dashboard: dashboard });
  };
  return (
    <React.Fragment>
      {getAppArray(role).map(([app, permissions]) => (
        <div key={`${role.name}-for-${app}`} className="ml-2">
          <Text type={TextType.h5} textClassName="border-b" inline={true}>
            {t('app_permissions', { app })}
          </Text>
          {permissions && permissions.length > 0 ? (
            <ul className="mb-4 mt-2 ml-6 permissions-list">
              {permissions.map((perm: Permission) => (
                <li key={`${role.name}-${perm.name}-${app}`}>
                  <Text>{perm.description ? perm.description : perm.name}</Text>
                </li>
              ))}
            </ul>
          ) : (
            <Text
              key={`${role.name}-for-${app}`}
              type={TextType.h6}
              textClassName="text-center text-blue-100 mt-2"
              inline
              overrideColor
              wrap
            >
              {permissions ? t('empty_permissions') : t('service_api_call_failed_warning', { app })}
            </Text>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

export default PermissionList;
