import debounce from 'debounce-promise';
import React, { useCallback, useEffect, useState } from 'react';

import { Pill, PillVariant } from '../Pill';
import { HeroIcons } from '../style/heroicons';
import { clipString } from '../util';
import FilterMenu from './FilterMenu';
import { DateRange, FilterKey, LoadOptions, MenuItem, Mode, MultiKey, OptionId, Range, RangeKey } from './index.types';

interface Props {
  filterKey: FilterKey;
  open?: OptionId;
  filter: FilterKey[];
  filterMounted: boolean;
  loadOptions: LoadOptions;
  setOpen: React.Dispatch<React.SetStateAction<OptionId | undefined>>;
  onChangeFilter: (filter: FilterKey[]) => void;
}

export default function SelectedFilterKey({
  filterKey,
  open,
  filter,
  filterMounted,
  loadOptions,
  setOpen,
  onChangeFilter,
}: Props): JSX.Element {
  const [selectedValues, setSelectedValues] = useState<MultiKey['selectedValues']>(
    structuredClone((filterKey as MultiKey).selectedValues),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsDebounced = useCallback(debounce(loadOptions, 500), [loadOptions]);

  // go through selected values and set the label if an option is found,
  // otherwise keep the existing label
  const combineSelectedValues = useCallback((oldSelectedValues: MenuItem[], newSelectedValues: MenuItem[]) => {
    return structuredClone(oldSelectedValues)?.map((oldValue: MenuItem) => {
      const value = newSelectedValues?.find((value) => value.id === oldValue.id);
      return {
        ...oldValue,
        ...(value ? { label: value.label ?? '(None)' } : {}),
      };
    });
  }, []);

  // load options on first render
  useEffect(() => {
    let cancelled = false;
    async function initLoadOptions() {
      const options = await loadOptionsDebounced(filterKey.id as string, undefined, true);
      if (cancelled) return;
      setSelectedValues(combineSelectedValues(selectedValues || [], options?.data || []));
    }

    if (!filterKey.mode || filterKey.mode === Mode.multi || filterKey.mode === Mode.single) {
      initLoadOptions();
    }

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // default label
  let label = '';

  const numberSelectedValues = selectedValues?.length ?? 0;
  if (filterKey.mode === Mode.range) {
    const min = filterKey.range?.from;
    const max = filterKey.range?.to;
    if (min !== undefined && max !== undefined) {
      label = `${min}-${max}`;
    } else if (max === undefined && min !== undefined) {
      label = `at least ${min}`;
    } else if (max !== undefined) {
      label = `up to ${max}`;
    }
  } else if (numberSelectedValues > 0) {
    if (selectedValues?.every((val) => typeof val.id === 'boolean')) {
      label = `${selectedValues.length === 1 && selectedValues[0].id ? 'Yes' : 'No'}`;
    } else {
      label = `${(filterKey as MultiKey).exclusionMode ? 'not ' : ''}${selectedValues?.[0].label} ${
        numberSelectedValues > 1 ? ` and ${numberSelectedValues - 1} more...` : ''
      }`;
    }
  } else if (filterKey.mode === Mode.dateRange && (filterKey?.range?.from || filterKey?.range?.to)) {
    label = `${
      filterKey?.range?.from instanceof Date
        ? filterKey?.range?.from?.toLocaleDateString()
        : filterKey?.range?.from?.toLocaleString()
    } to ${
      filterKey?.range?.to instanceof Date
        ? filterKey?.range?.to?.toLocaleDateString()
        : filterKey?.range?.to?.toLocaleString()
    }`;
  } else if (filterKey.textSearch) {
    label = `"${filterKey.textSearch}"`;
  }

  // strip html tags
  label = label.toString().replace(/<[/a-z]*>/g, '');

  // clip to max number of characters
  label = `${filterKey.label}: ${clipString(label, 36)}`;

  /**
   * Takes a subset of all possible filter items and edits the filter object to reflect those changes, preserving any other changes
   * made previously.
   */
  const handleChangeFilterKey = ({
    id,
    selectedValues,
    range,
    exclusionMode,
    textSearch,
  }: {
    id: OptionId;
    selectedValues?: MenuItem[];
    range?: Range | DateRange;
    exclusionMode?: boolean;
    textSearch?: string;
  }) => {
    // remove keys with nothing selected
    if (
      (!selectedValues || selectedValues?.length === 0) &&
      !exclusionMode &&
      (!range || (!range.from && !range.to)) &&
      !textSearch
    ) {
      return removeFilterKey(id);
    }

    setSelectedValues(selectedValues);

    const newFilter = structuredClone(filter).map((filterKey: FilterKey) => {
      if (filterKey.id === id) {
        return {
          ...filterKey,
          selectedValues,
          range,
          exclusionMode,
          textSearch,
        };
      }
      return filterKey;
    }) as FilterKey[];

    onChangeFilter(newFilter);
  };

  const removeFilterKey = (id: OptionId) => {
    let newFilter: FilterKey[] = structuredClone(filter);
    newFilter = newFilter.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: false,
          selectedValues: (item as MultiKey).selectedValues ? [] : undefined,
          range: (item as RangeKey).range ? {} : undefined,
          exclusionMode: undefined,
          textSearch: undefined,
        };
      }
      return item;
    }) as FilterKey[];

    onChangeFilter(newFilter);
  };

  return (
    <FilterMenu
      optionsTestId="category-options-selector"
      dropdownTestId={`selected-filter-${filterKey.id ?? ''}`}
      selectedValues={selectedValues}
      range={(filterKey as RangeKey).range}
      mode={filterKey.mode ? filterKey.mode : (filterKey as RangeKey).range ? Mode.dateRange : Mode.multi}
      onChange={({ selectedValues, range, exclusionMode, textSearch }) => {
        handleChangeFilterKey({
          id: filterKey.id,
          selectedValues,
          range,
          exclusionMode,
          textSearch,
        });
      }}
      exclusionMode={(filterKey as MultiKey).exclusionMode}
      // prevent menus from opening when the filter first renders
      openOnMount={filterMounted && filterKey.openOnSelect !== false}
      loadOptions={async (searchTerm?: string) => {
        return loadOptionsDebounced(filterKey.id as string, searchTerm);
      }}
      onOpen={() => setOpen(filterKey.id)}
      onClose={() => setOpen(undefined)}
      textSearch={filterKey.textSearch}
      searchable={filterKey.searchable}
    >
      <Pill
        testId="category-pill"
        data-pwid={`category-pill-${filterKey.label ?? filterKey.id ?? 'no-label'}`}
        variant={open === filterKey.id ? PillVariant.highlighted : PillVariant.secondary}
        leftIcon={open === filterKey.id ? HeroIcons.ChevronDownIcon : undefined}
        rightIcon={HeroIcons.XCircleIcon}
        propagateLeftIconClickEvent
        onClickRightIcon={() => removeFilterKey(filterKey.id)}
      >
        {label}
      </Pill>
    </FilterMenu>
  );
}
