import React from 'react';
import { Navigate, Route, Routes as RRoutes } from 'react-router-dom';

import { AuthContext, useAuthContextValue } from './context/Auth';
import Account from './pages/Account/Account';
import AuthSuccess from './pages/AuthSuccess';
import ChangeEmail from './pages/ChangeEmail';
import ForgotPassword from './pages/ForgotPassword';
import Invite from './pages/Invite';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import Users from './pages/Users';

const Routes = (): JSX.Element => {
  return (
    <RRoutes>
      <Route path="login">
        <Route index element={<Login />} />
        <Route path=":uid/callback" element={<Login />} />
      </Route>
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/changeEmail" element={<ChangeEmail />} />
      <Route path="/users">
        <Route index element={<Users />} />
        <Route path=":email" element={<Account />} />
      </Route>
      <Route path="/" element={<Navigate replace to="/users" />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/invite" element={<Invite />} />
      <Route path="/authSuccess" element={<AuthSuccess />} />
    </RRoutes>
  );
};

export default function RoutesContainer(): JSX.Element {
  const authContextValue = useAuthContextValue();

  return (
    <AuthContext.Provider value={authContextValue}>
      <Routes />
    </AuthContext.Provider>
  );
}
