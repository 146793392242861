import { AxiosError } from 'axios';

import { Roles } from './components/SignupStages/index.types';
import { RoleNames } from './util/permissions';

export enum UserState {
  disabled = 'disabled',
  active = 'active',
  pending = 'pending',
}

export interface System {
  _id: string;
  sysId: string;
  label?: string;
}

export interface Profile {
  firstName: string;
  lastName: string;
  phone: string;
  mobile: string;
  company: string;
  role: Roles | '';
}

export interface EditProfile {
  firstName?: string;
  lastName?: string;
  phone?: string;
  mobile?: string;
  company?: string;
  role?: Roles | '';
}

export interface User {
  _id?: string;
  email: string;
  profile: Profile;
  roles: string[] | null;
  userState?: UserState;
  created?: Date | string;
  lastLogin?: Date | string;
  password: string;
  distributor?: Distributor;
  access?: UserAccess;
  settableRoles?: Role[];
  permissions?: string[];
  isDeveloper?: boolean;
  isInternal?: boolean;
  isDistributor?: boolean;
}

export interface EditUser {
  profile?: EditProfile;
  roles?: string[];
  userState?: UserState;
  distributor?: string | null;
  access?: UserAccess;
  isDistributor?: boolean;
}

export interface UserSanitized extends Omit<User, 'password'> {
  password?: string;
}

export interface DecodedToken {
  permissions: string[];
  settableRoles: string[];
  isInternal: boolean;
  isDeveloper: boolean;
  sub: string;
  firstName?: string;
  lastName?: string;
}

export interface UserAccess {
  allOrgs?: boolean;
  orgs?: OrganizationAccess[];
}

export interface Distributor {
  _id: string;
  name: string;
  orgIds?: string[];
  label?: string;
}

export interface Organization {
  _id: string;
  name: string;
  label?: string;
}

export interface OrganizationAccess extends Organization {
  allSites: boolean;
  sites: Site[];
}

export interface Site {
  _id: string;
  name: string;
  allSystems?: boolean;
  systems: string[];
  label?: string;
}

export interface DistributorRecord extends Distributor {
  _id: string;
}

export interface Permission {
  name: string;
  description: string;
}

export interface Role {
  name: RoleNames;
  accounts: Permission[] | null;
  dashboard: Permission[] | null;
}

export type OnPermissionFail = (error: AxiosError) => void;
