import debounce from 'debounce-promise';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';
import QueryString from 'qs';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Api from '../adapters/accounts';
import { getAuthorizationCode } from '../adapters/accounts/auth';
import { JsonApiQuery, queryToUrl, Sort } from '../JsonApi/src';
import { FilterKey, MenuItem } from '../JsonApi/src/types';
import { User } from '../types';

interface UsersResponse {
  users: User[];
  count: number;
  dailyActiveUsers: number;
  weeklyActiveUsers: number;
  monthlyActiveUsers: number;
  serverTimeMs?: DateTime;
}
interface GetUsersArgs {
  filterKeys: FilterKey[];
  countPerPage: number;
  page?: number;
  sort?: Sort;
  textSearch?: string;
  pageChangedManually?: boolean;
}
export interface UsersContextData {
  totalUsers: number;
  dailyActiveUsers: number;
  weeklyActiveUsers: number;
  monthlyActiveUsers: number;
  users: User[];
  serverTime?: DateTime;
  getUsers: (params: GetUsersArgs | GetUsersArgs[][]) => Promise<UsersResponse | void>;
  downloadUsers: (filter: FilterKey[], sort?: Sort, textSearch?: string) => Promise<void>;
  fetchUsersFieldKeys: ({
    filterKeys,
    key,
    searchTerm,
    onlySelected,
  }: {
    filterKeys: FilterKey[];
    key: string;
    searchTerm?: string;
    onlySelected?: boolean;
  }) => Promise<{
    data: MenuItem[];
    count: number;
  }>;
}

const usersContextDefaultValue: UsersContextData = {
  totalUsers: 0,
  dailyActiveUsers: 0,
  weeklyActiveUsers: 0,
  monthlyActiveUsers: 0,
  users: [],
  serverTime: undefined,
  getUsers: async () => ({
    users: [],
    count: 0,
    dailyActiveUsers: 0,
    weeklyActiveUsers: 0,
    monthlyActiveUsers: 0,
  }),
  downloadUsers: async () => undefined,
  fetchUsersFieldKeys: async () => ({
    data: [],
    count: 0,
  }),
};

export const useUsersContextValue = (): UsersContextData => {
  const [users, setUsers] = useState<User[]>(usersContextDefaultValue.users);
  const [totalUsers, setTotalUsers] = useState(usersContextDefaultValue.totalUsers);
  const [dailyActiveUsers, setDailyActiveUsers] = useState(usersContextDefaultValue.dailyActiveUsers);
  const [weeklyActiveUsers, setWeeklyActiveUsers] = useState(usersContextDefaultValue.weeklyActiveUsers);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(usersContextDefaultValue.monthlyActiveUsers);
  const [serverTime, setServerTime] = useState<DateTime | undefined>();
  const navigate = useNavigate();

  const getUsers = debounce(
    async (invocationsArgTuples: GetUsersArgs | GetUsersArgs[][]) => {
      const lastTuple = (invocationsArgTuples as GetUsersArgs[][])[
        (invocationsArgTuples as GetUsersArgs[][]).length - 1
      ];
      const args = cloneDeep(lastTuple[0]);

      const { filterKeys, countPerPage, page, sort, textSearch, pageChangedManually } = args;

      if ((invocationsArgTuples as GetUsersArgs[][]).length > 1) {
        // prevent race condition between filter and page by setting page to 1
        let filtersEqual = true;
        for (const invocationArg of invocationsArgTuples as GetUsersArgs[][]) {
          if (!isEqual(invocationArg[0].filterKeys, args.filterKeys)) {
            filtersEqual = false;
            break;
          }
        }

        if (filtersEqual && !pageChangedManually) args.page = 1;
      }

      const query: JsonApiQuery = {
        filterKeys,
      };
      if (countPerPage && page) {
        query.page = {
          number: page,
          size: countPerPage,
        };
      }

      if (sort) {
        query.sort = sort;
      }

      const params = queryToUrl(query);

      if (textSearch) params.textSearch = textSearch;
      const onFail = () => {
        navigate('/users/current');
      };
      return Api.request({ method: 'get', path: `users`, params, onFail })
        .then((response) => {
          const data = response as UsersResponse;
          if (data.serverTimeMs) setServerTime(data.serverTimeMs);
          setUsers(data.users);
          setTotalUsers(data.count);
          setDailyActiveUsers(data.dailyActiveUsers);
          setWeeklyActiveUsers(data.weeklyActiveUsers);
          setMonthlyActiveUsers(data.monthlyActiveUsers);
          return data;
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            getAuthorizationCode();
          }
        });
    },
    300,
    { accumulate: true },
  );

  const downloadUsers = async (filterKeys: FilterKey[], sort?: Sort, textSearch?: string) => {
    const query: JsonApiQuery = {
      filterKeys,
    };

    if (sort) {
      query.sort = sort;
    }

    const params = queryToUrl(query);

    if (textSearch) {
      params.textSearch = textSearch;
    }

    const downloadUrl = `${process.env.REACT_APP_ACCOUNTS_API_URL}/users/download?${QueryString.stringify(params, {
      arrayFormat: 'repeat',
    })}`;
    window.open(downloadUrl, '_blank');
  };

  const fetchUsersFieldKeys = async ({
    filterKeys,
    key,
    searchTerm,
    onlySelected,
  }: {
    filterKeys: FilterKey[];
    key: string;
    searchTerm?: string;
    onlySelected?: boolean;
  }): Promise<{
    data: MenuItem[];
    count: number;
  }> => {
    if (key === 'created' || key === 'lastLogin') {
      return {
        data: [],
        count: 0,
      };
    }
    // ignore filters on the current key
    const filter = cloneDeep(filterKeys);
    const sanitizedFilter = filter.filter((item) => (onlySelected ? item.id === key : item.id !== key));

    const query: JsonApiQuery = {
      filterKeys: sanitizedFilter,
      page: {
        number: 1,
        size: 25,
      },
    };

    const params = queryToUrl(query);

    if (searchTerm) {
      params.search = searchTerm;
    }

    return Api.request({ method: 'get', path: `users/filterKeys/${key}`, params }) as Promise<{
      data: MenuItem[];
      count: number;
    }>;
  };

  return {
    totalUsers,
    dailyActiveUsers,
    weeklyActiveUsers,
    monthlyActiveUsers,
    users,
    serverTime,
    getUsers,
    downloadUsers,
    fetchUsersFieldKeys,
  };
};

export const UsersContext = createContext<UsersContextData>(usersContextDefaultValue);
