import { createContext, useState } from 'react';
import { toast } from 'react-toastify';

import Api from '../adapters/accounts';
import { Roles } from '../components/SignupStages/index.types';
import { EditUser, OnPermissionFail, User, UserAccess, UserState } from '../types';

interface SignupData {
  email: string;
  password: string;
  signupToken: string;
  userState: UserState;
  created: string | Date;
  profile: {
    firstName?: string;
    lastName?: string;
    company?: string;
    role?: Roles | '';
    phone?: string;
    mobile?: string;
  };
}

export interface UserContextData {
  user: User;
  userDiff: EditUser | undefined;
  isLoading: boolean;
  setUser: (user: User) => void;
  setUserDiff: (diff?: EditUser) => void;
  getUser: (email: string) => Promise<User | undefined>;
  getCurrentUser: () => Promise<User | undefined>;
  saveUser: (onFail: OnPermissionFail) => void;
  disableUser: (email?: string) => Promise<void>;
  enableUser: (email?: string) => Promise<void>;
  requestReset: (email: string) => Promise<void>;
  requestChangeEmail: (email: string, newEmail: string) => Promise<void>;
  reset: (password: string, token: string) => Promise<void>;
  changeEmail: (token: string) => Promise<void>;
  signup: (data: SignupData) => Promise<boolean | void>;
  validateResetToken: (token: string) => Promise<boolean>;
  validateSignupToken: (token: string) => Promise<User | undefined>;
  invite: (access?: UserAccess, distributor?: string | null) => Promise<void>;
  updateInvite: (email: string) => Promise<void>;
}

const userContextDefaultValue: UserContextData = {
  user: {
    _id: '',
    email: '',
    password: '',
    profile: {
      firstName: '',
      lastName: '',
      phone: '',
      mobile: '',
      company: '',
      role: '',
    },
    roles: [],
  },
  userDiff: undefined,
  isLoading: false,
  setUser: () => null,
  setUserDiff: () => null,
  getUser: async () => undefined,
  getCurrentUser: async () => undefined,
  saveUser: async () => undefined,
  disableUser: async () => undefined,
  enableUser: async () => undefined,
  requestReset: async () => {
    return;
  },
  requestChangeEmail: async () => {
    return;
  },
  reset: async () => {
    return;
  },
  changeEmail: async () => {
    return;
  },
  signup: async () => {
    return true;
  },
  validateResetToken: async () => {
    return true;
  },
  validateSignupToken: async () => undefined,
  invite: async () => {
    return;
  },
  updateInvite: async () => {
    return;
  },
};

export const useUserContextValue = (): UserContextData => {
  const [user, _setUser] = useState<User>(userContextDefaultValue.user);
  const [userDiff, setUserDiff] = useState<EditUser | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const setUser = (newUser: User) => {
    _setUser({
      ...user,
      ...newUser,
    });
  };

  const getUser = async (email: string) => {
    setIsLoading(true);

    return Api.request({ method: 'get', path: `users/${encodeURIComponent(email)}` })
      .then((response) => {
        setUser(response as User);
        return response as User;
      })
      .finally(() => setIsLoading(false));
  };

  const getCurrentUser = async () => {
    setIsLoading(true);

    return Api.request({ method: 'get', path: 'users/current' })
      .then((response) => {
        const userResponse = response as User;
        setUser({
          ...userResponse,
          email: userResponse?.email,
          profile: {
            ...userResponse?.profile,
            ...userResponse?.profile,
          },
          roles: [...(userResponse?.roles ?? [])],
        });
        return userResponse;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveUser = async (onFail: OnPermissionFail) => {
    setIsLoading(true);

    const res = Api.request({
      method: 'patch',
      path: `users/${encodeURIComponent(user?.email)}`,
      data: userDiff,
      onFail,
    });
    return res;
  };

  const enableUser = async (email?: string) => {
    return Api.request({
      method: 'patch',
      path: `users/${encodeURIComponent(email ?? '')}`,
      data: {
        userState: UserState.active,
      },
    }).catch() as Promise<void>;
  };

  const disableUser = async (email?: string) => {
    return Api.request({
      method: 'patch',
      path: `users/${encodeURIComponent(email ?? '')}`,
      data: {
        userState: UserState.disabled,
      },
    }).catch() as Promise<void>;
  };

  const validateResetToken = async (token: string): Promise<boolean> => {
    try {
      await Api.request({
        method: 'get',
        path: `users/validateResetToken/${token}`,
        auth: false,
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  const validateSignupToken = async (token: string): Promise<User> => {
    const response = (await Api.request({
      method: 'get',
      path: `users/validateSignupToken/${token}`,
      auth: false,
    })) as {
      data: User;
    };
    setUser(response.data);
    return response.data;
  };

  const requestReset = async (email: string): Promise<void> => {
    return Api.request({
      method: 'post',
      path: 'users/requestReset',
      data: { email },
      auth: false,
    }).catch() as Promise<void>;
  };

  const requestChangeEmail = async (email: string, newEmail: string): Promise<void> => {
    return Api.request({
      method: 'post',
      path: 'users/requestChangeEmail',
      data: { email, newEmail },
    }).catch() as Promise<void>;
  };

  const reset = async (password: string, token: string): Promise<void> => {
    return Api.request({
      method: 'post',
      path: 'users/reset',
      data: { password, token },
      auth: false,
    }).catch() as Promise<void>;
  };

  const changeEmail = async (token: string): Promise<void> => {
    return Api.request({ method: 'post', path: 'users/changeEmail', data: { token } }).catch() as Promise<void>;
  };

  const signup = async (data: SignupData): Promise<boolean | void> => {
    return Api.request({
      method: 'post',
      path: 'users/signup',
      data: {
        email: data.email,
        password: data.password,
        signupToken: data.signupToken,
        userState: data.userState,
        created: data.created,
        profile: data.profile,
      },
      auth: false,
    })
      .then(() => {
        return true;
      })
      .catch((err) => alert(`Error: ${err.message}`));
  };

  const invite = async (access?: UserAccess, distributor?: string | null): Promise<void> => {
    try {
      if (!user?.email) throw Error('no email address provided');

      await Api.request({
        method: 'post',
        path: `users/invite/${encodeURIComponent(user?.email)}`,
        data: {
          roles: user.roles,
          profile: user.profile,
          access,
          distributor,
        },
      });
      toast('Success: The invitation email was sent.', { type: 'success' });
    } catch (error: unknown) {
      const err = error as {
        response: {
          data: {
            error: string;
          };
          status: number;
        };
      };
      if (err.response?.status === 400) {
        toast(`Error: ${err.response?.data?.error} The invitation email was not sent.`, { type: 'error' });
      } else {
        toast('Error: The invitation email was not sent.', { type: 'error' });
      }
    }
  };

  const updateInvite = async (email: string): Promise<void> => {
    try {
      await Api.request({
        method: 'post',
        path: `users/invite/${encodeURIComponent(email)}`,
      });
      toast('Success: The invitation email was sent.', { type: 'success' });
    } catch (error: unknown) {
      const err = error as {
        response: {
          data: {
            error: string;
          };
          status: number;
        };
      };
      if (err.response?.status === 400 || err.response?.status === 429) {
        toast(`The invitation email was not sent. Error: ${err.response?.data?.error}`, {
          type: 'error',
          autoClose: 10000,
        });
      } else {
        toast('Error: The invitation email was not sent.', { type: 'error' });
      }
    }
  };

  return {
    user,
    userDiff,
    isLoading,
    setUser,
    setUserDiff,
    getUser,
    getCurrentUser,
    saveUser,
    enableUser,
    disableUser,
    validateResetToken,
    validateSignupToken,
    requestReset,
    requestChangeEmail,
    reset,
    changeEmail,
    signup,
    invite,
    updateInvite,
  };
};

export const UserContext = createContext<UserContextData>(userContextDefaultValue);
