import { PlusIcon } from '@heroicons/react/outline';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchSearchOrgs, fetchUserAccess, SearchOrgsResponse } from '../../adapters/smartview/endpoints';
import { AsyncSelect, Checkbox, Form, FormSave, Loading, ReactSelectOption } from '../../ComponentLibrary/src';
import Organization from '../../components/Organization';
import { AuthContext } from '../../context/Auth';
import { UserContext } from '../../context/User';
import { OrganizationAccess } from '../../types';
import { PERMISSIONS } from '../../util/constants';

interface Props {
  newUserMode: boolean;
  formStatus?: 'dirty' | 'success' | 'loading';
  onSaveUser?: () => Promise<void>;
  setFormStatus?: (status?: 'dirty' | 'success' | 'loading') => void;
  onCancelSaveUser?: () => void;
}

export default function Access({
  newUserMode,
  formStatus,
  setFormStatus,
  onSaveUser,
  onCancelSaveUser,
}: Props): JSX.Element {
  const { email: emailUrlParam } = useParams<{ email: string }>();
  const [loading, setLoading] = useState(true);
  const { hasPermissions } = useContext(AuthContext);
  const { user, setUser, userDiff, setUserDiff } = useContext(UserContext);

  const emailParam = emailUrlParam?.toLowerCase() ?? '';
  const canEditAccess = hasPermissions(PERMISSIONS.qnergyView.users.updateAccess) && emailParam !== 'current';

  const access = userDiff?.access ?? user.access;

  const syncUpdatedAccess = () => {
    return fetchUserAccess(user.email)
      .then(({ access }) => {
        setUser({
          ...user,
          access,
        });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!newUserMode && user.email) {
      syncUpdatedAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadOptions = async (searchTerm: string) => {
    return fetchSearchOrgs({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      searchTerm,
      count: true,
      filterKeys: [
        {
          id: '_id',
          label: 'id',
          selected: true,
          exclusionMode: true,
          selectedValues: access?.orgs?.map((org) => ({
            id: org._id,
            label: org.name,
          })),
        },
      ],
    })
      .then((response) => {
        if (response) {
          const { orgs, count } = response as SearchOrgsResponse;
          const options: ReactSelectOption[] = orgs.map((org) => ({
            value: org._id,
            label: org.label ?? org.name,
          }));
          if (count > orgs.length) {
            options.push({
              value: 'more',
              label: `and ${count - orgs.length} more...`,
              isDisabled: true,
            });
          }
          return options;
        }
        return [];
      })
      .catch(() => []);
  };

  const handleAddOrg = (newOrganization: ReactSelectOption | null) => {
    const newAccess = cloneDeep(access) ?? {
      orgs: [],
    };
    if (!newAccess.orgs) newAccess.orgs = [];
    if (newOrganization && newAccess?.orgs && !newAccess.orgs.some((org) => org._id === newOrganization.value)) {
      newAccess.orgs.push({
        _id: newOrganization.value as string,
        allSites: true,
        sites: [],
        name: newOrganization.label.toString().replace(/<[/b]*>/g, '') as string,
      });
      setUserDiff({
        ...userDiff,
        access: {
          ...userDiff?.access,
          ...newAccess,
        },
      });
      if (setFormStatus) setFormStatus('dirty');
    }
  };

  const handleAddSiteToOrg = (
    site: {
      _id: string;
      name: string;
    },
    orgId: string,
  ): void => {
    const orgs = access?.orgs?.map((org) => {
      if (org._id === orgId) {
        org.sites.push({
          ...site,
          name: site.name.toString().replace(/<[/b]*>/g, ''),
          allSystems: true,
          systems: [],
        });
      }
      return org;
    });
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        orgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleRemoveOrg = (orgId: string): void => {
    const newOrgs = access?.orgs?.filter((org: OrganizationAccess) => org._id !== orgId);
    if (newOrgs) {
      setUserDiff({
        ...userDiff,
        access: {
          ...userDiff?.access,
          orgs: newOrgs,
        },
      });
    }
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleRemoveSite = (siteId: string, orgId: string): void => {
    const newOrgs = access?.orgs?.map((org: OrganizationAccess) => {
      if (org._id === orgId) {
        org.sites = org.sites.filter((site) => site._id !== siteId);
      }
      return org;
    });
    if (newOrgs) {
      setUserDiff({
        ...userDiff,
        access: {
          ...userDiff?.access,
          orgs: newOrgs,
        },
      });
    }
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleRemoveSystem = (systemId: string, orgId: string, siteId: string): void => {
    const newOrgs = access?.orgs?.map((org: OrganizationAccess) => {
      if (org._id === orgId) {
        org.sites = org.sites?.map((site) => {
          if (site._id === siteId) {
            site.systems = site.systems.filter((system) => system !== systemId);
          }
          return site;
        });
      }
      return org;
    });
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        orgs: newOrgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleAddSystem = (systemId: string, orgId: string, siteId: string): void => {
    const newOrgs = access?.orgs?.map((org: OrganizationAccess) => {
      if (org._id === orgId) {
        org.sites = org.sites?.map((site) => {
          if (site._id === siteId) {
            if (!site.systems) site.systems = [];
            site.systems.push(systemId);
          }
          return site;
        });
      }
      return org;
    });
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        orgs: newOrgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangeAllOrgs = (allOrgs: boolean): void => {
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        allOrgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangeAllSites = (orgId: string, allSites: boolean): void => {
    const newOrgs = access?.orgs?.map((org: OrganizationAccess) => {
      if (org._id === orgId) {
        org.allSites = allSites;
      }
      return org;
    });
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        orgs: newOrgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangeAllSystems = (orgId: string, siteId: string, allSystems: boolean): void => {
    const newOrgs = access?.orgs?.map((org: OrganizationAccess) => {
      if (org._id === orgId) {
        org.sites = org.sites?.map((site) => {
          if (site._id === siteId) {
            site.allSystems = allSystems;
          }
          return site;
        });
      }
      return org;
    });
    setUserDiff({
      ...userDiff,
      access: {
        ...userDiff?.access,
        orgs: newOrgs,
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleSubmit = (): void => {
    if (onSaveUser) {
      onSaveUser()
        .then(() => {
          syncUpdatedAccess();
          if (setFormStatus) setFormStatus(undefined);
        })
        .catch(() => {
          if (setFormStatus) setFormStatus('dirty');
        });
    }
  };

  const content = (
    <>
      <Checkbox
        className="w-full"
        onChangeValue={handleChangeAllOrgs}
        checked={access?.allOrgs ?? false}
        hideErrorSection
        disabled={!canEditAccess}
        label="Access All Organizations"
      ></Checkbox>
      <div className="relative flex-1 justify-start flex flex-col gap-2">
        {!access?.allOrgs &&
          access?.orgs &&
          access?.orgs.length > 0 &&
          access?.orgs.map((org: OrganizationAccess) => {
            return (
              <Organization
                key={org._id}
                org={org}
                onAddSiteToOrg={handleAddSiteToOrg}
                onRemoveOrg={handleRemoveOrg}
                onRemoveSite={handleRemoveSite}
                onRemoveSystem={handleRemoveSystem}
                onAddSystem={handleAddSystem}
                onChangeAllSites={handleChangeAllSites}
                onChangeAllSystems={handleChangeAllSystems}
                disabled={!canEditAccess}
              />
            );
          })}
        {/* can't edit one's own access */}
        {!access?.allOrgs && canEditAccess && (
          <AsyncSelect
            key={JSON.stringify(access?.orgs)}
            className="w-full sm:w-1/2"
            tooltip="The organization or customer entity name"
            onLoadOptions={handleLoadOptions}
            filterOption={(option) => !access?.orgs?.some((org) => org._id === option.value)}
            placeholder="Org Name"
            menuPlacement="bottom"
            onChange={handleAddOrg}
            hideErrorSection
            singleSelectMode
            icon={<PlusIcon className="h-6 text-gray-400" />}
          />
        )}
      </div>
    </>
  );

  return !newUserMode && loading ? (
    <div className="h-full flex justify-center items-center">
      <Loading type="medium" />
    </div>
  ) : (
    <Form className="flex flex-col gap-4 h-full p-4 w-full" preventDefault onSubmit={handleSubmit}>
      {content}
      {!newUserMode && (emailParam !== 'current' || formStatus !== undefined) && canEditAccess && (
        <FormSave
          className="self-end"
          onCancel={onCancelSaveUser}
          mode={newUserMode ? 'create' : 'edit'}
          status={formStatus}
        />
      )}
    </Form>
  );
}
