import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import packageJson from '../../package.json';
import { Card, Link, Link as QnergyLink, Loading, Text } from '../ComponentLibrary/src';
import logo from '../ComponentLibrary/src/style/Qnergy_HOR_NoTag_RGB.svg';
import AuthWallBg from '../components/Bg';
import { UserContext, useUserContextValue } from '../context/User';
import { useQuery } from '../hooks';
const REDIRECT_SECS = 5;

const ResetPassword: FunctionComponent = () => {
  const { changeEmail } = useContext(UserContext);
  const query = useQuery();
  const [success, setSuccess] = useState<boolean>();
  const [redirectSecs, setRedirectSecs] = useState(REDIRECT_SECS);

  useEffect(() => {
    const token = query.get('changeToken');
    changeEmail(token || '')
      .then(() => setSuccess(true))
      .catch(() => {
        setSuccess(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        if (redirectSecs === 1) {
          window.location.href = `${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/users/current`;
          clearTimeout(timeoutId);
        }
        setRedirectSecs(redirectSecs - 1);
      }, 1000);
    }
  }, [redirectSecs, success]);

  let content;
  const successContent = (
    <>
      <Text wrap className="flex flex-col items-center text-center">
        The email address was successfully changed.
      </Text>
      <br />
      <Text wrap className="flex flex-col items-center text-center">
        This page will redirect in {redirectSecs} second{`${redirectSecs > 1 ? 's' : ''}`}.
      </Text>
      <br />
      <Link url={`${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/users/current`}>Return to account page</Link>
    </>
  );
  const failedContent = (
    <>
      <Text className="flex flex-col items-center text-center text-red-600" overrideColor wrap>
        There was an error changing the email address.
      </Text>
      <br />
      <Link url={`${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/users/current`}>Return to account page</Link>
    </>
  );

  if (success) {
    content = successContent;
  } else if (success === false) {
    content = failedContent;
  } else {
    return <Loading />;
  }

  return (
    <AuthWallBg>
      <Card className="inline-flex flex-col md:p-8 lg:p-16 rounded-lg gap-y-4 max-w-sm max-h-full items-center">
        <img src={logo} className="max-w-full" />

        <div className="w-full flex flex-col items-center p-4">{content}</div>
        <div className="flex flex-row justify-around w-full lg:p-4 items-center flex-wrap">
          <Text className="font-thin">{packageJson.version || 'ver?'}</Text>
          <Text className="font-thin">©2021 Qnergy</Text>
          <QnergyLink url={`${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/terms`}>Terms</QnergyLink>
        </div>
      </Card>
    </AuthWallBg>
  );
};

export default function ResetPasswordContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <ResetPassword />
    </UserContext.Provider>
  );
}
