import axios, { AxiosError, Method } from 'axios';
import { DateTime } from 'luxon';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import i18n from '../../i18n/index';
import { OnPermissionFail, User } from '../../types';
import { ACCOUNTS_API_URL } from '../../util/constants';
import { getAuthorizationCode } from './auth';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures#emulating_private_methods_with_closures
export default (function () {
  // public variables
  let user: User;
  let apiDown = false;
  let authorized = false;

  return {
    isApiDown: () => apiDown,
    setUser: (info: User) => {
      user = info;
      window.dispatchEvent(new Event('userChanged'));
    },
    getUser: () => user,
    /** This allows react components to trigger renders when token changes */
    useRenderOnUserChange: () => {
      // You have to change state to get a re-render (e.g. for page access control)
      const [toggler, forceRender] = useState(false);

      useEffect(() => {
        const handleTokenChanged = () => {
          forceRender(!toggler);
        };
        window.addEventListener('userChanged', handleTokenChanged);

        return () => window.removeEventListener('userChanged', handleTokenChanged);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    },
    request: async ({
      method,
      path,
      data,
      params,
      onFail,
    }: {
      method?: Method;
      path: string;
      data?: unknown;
      auth?: boolean;
      params?: unknown;
      onFail?: OnPermissionFail;
    }): Promise<unknown> => {
      try {
        const headers: Record<string, unknown> = {};

        const response = await axios({
          method,
          url: `${process.env.REACT_APP_ACCOUNTS_API_URL || ACCOUNTS_API_URL}/${path}`,
          withCredentials: true,
          headers,
          data,
          params,
          paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
        });

        const responseData = response.data;

        if (response.headers['x-server-time'] && typeof responseData === 'object' && !Array.isArray(responseData)) {
          responseData.serverTimeMs = DateTime.fromMillis(parseInt(response.headers['x-server-time']));
        }

        apiDown = false;
        return responseData;
      } catch (error: unknown) {
        if ((error as { message: string }).message === 'Network Error') {
          apiDown = true;
          toast('There is a problem with the server. Please contact an admin or try again later.', {
            type: 'error',
            toastId: 'NetworkError',
            autoClose: false,
          });
          // force a re-render
          window.dispatchEvent(new Event('DashboardTokenChanged'));
          return;
        } else {
          apiDown = false;
        }
        const err = error as {
          response: {
            data: {
              error: string;
            };
            status: number;
          };
        };
        if (err.response?.status === 401 && !authorized) {
          authorized = false;
          getAuthorizationCode();
        } else {
          authorized = true;
        }

        if ((err as AxiosError).response?.status === 403) {
          if (onFail) return onFail(error as AxiosError);
          toast(i18n.t('Access Forbidden'), {
            type: toast.TYPE.ERROR,
            toastId: 'access',
            autoClose: false,
          });
          window.location.href = '/';
        }
        throw err;
      }
    },
  };
})();
