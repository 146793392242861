export enum RoleNames {
  reader = 'Reader',
  qnergyAdmin = 'Qnergy Admin',
  postActionLogs = 'Post Action Logs',
  manageActionLogs = 'Manage Action Logs',
  distributor = 'Distributor',
  advancedReader = 'Advanced Reader',
  customerService = 'Customer Service',
  readActionLogs = 'Read Action Logs',
  smartviewDeveloper = 'SmartView Developer',
  qnergyAtp = 'Qnergy ATP',
  qnergyEngineer = 'Qnergy Engineer',
  basicLynxUser = 'Basic Lynx User',
  contractor = 'Contractor',
  oemCustomer = 'OEM Customer',
  sales = 'Sales',
  viewNewFeatures = 'View New Features',
  qnergyReader = 'Qnergy Reader',
  qnergyLogistics = 'Qnergy Logistics',
}
