import { FilterKey, Sort } from '../../ComponentLibrary/src';
import { JsonApiQuery, jsonToUrl, queryToUrl } from '../../JsonApi/src';
import { Distributor, Organization, Site, System, User } from '../../types';
import Api from '.';

export interface SearchOrgsResponse {
  count: number;
  orgs: Organization[];
}

export async function fetchUserAccess(email: string): Promise<User> {
  return Api.request({
    method: 'get',
    path: `users/${encodeURIComponent(email)}`,
  }).then((response) => response as User);
}

export async function fetchDistributors(options?: { sort?: Sort; searchTerm?: string }): Promise<Distributor[]> {
  const query: JsonApiQuery = options
    ? {
        sort: options.sort,
      }
    : {};

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = options?.searchTerm;
  }

  return Api.request({ method: 'get', path: 'distributors', params }).then((response) => {
    if (options?.searchTerm) {
      return (
        response as {
          count: number;
          distributors: Distributor[];
        }
      ).distributors;
    } else {
      return response as Distributor[];
    }
  });
}

export async function fetchDistributorById(id: string): Promise<Distributor> {
  const params = {
    ['filter[_id]']: id,
  };

  return Api.request({ method: 'get', path: 'distributors', params }).then((response) => {
    return (response as Distributor[])?.[0];
  });
}

export async function fetchSitesForOrg(options: {
  filter?: Record<string, unknown>;
  searchTerm?: string;
}): Promise<Site[]> {
  const { filter, searchTerm } = options;
  const query = {
    filter,
  };

  const params = query ? jsonToUrl(query) : {};

  if (searchTerm) {
    params.textSearch = searchTerm;
  }
  return Api.request({ method: 'get', path: 'sites', params }).then((response) => response as Site[]);
}
export async function fetchSystemsForSite(filter: Record<string, unknown>, textSearch?: string): Promise<System[]> {
  const query = {
    filter,
    project: ['sysId', 'siteId'],
  };

  const params = query ? jsonToUrl(query) : {};

  if (textSearch) {
    params.textSearch = textSearch;
  }
  return Api.request({ method: 'get', path: 'systems/summary', params }).then(
    (response) =>
      (
        response as {
          systems: System[];
        }
      ).systems as System[],
  );
}

export async function fetchSearchOrgs(options?: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  count?: boolean;
  project?: string[];
  searchTerm?: string;
  distributorId?: string;
  filterKeys?: FilterKey[];
}): Promise<Organization[] | SearchOrgsResponse | void> {
  const query: JsonApiQuery = options
    ? {
        project: options.project,
        sort: options.sort,
      }
    : {};

  if (options?.countPerPage && options?.pageNumber) {
    query.page = {
      number: options?.pageNumber,
      size: options?.countPerPage,
    };
  }

  if (options?.filterKeys) {
    query.filterKeys = options.filterKeys;
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = options?.searchTerm;
  }

  if (options?.distributorId) {
    params['distributorId'] = options?.distributorId;
  }

  if (options?.count) {
    params.count = options?.count;
  }

  return Api.request({ method: 'get', path: 'orgs', params }).then((response) => {
    return response as Organization[] | SearchOrgsResponse;
  });
}
