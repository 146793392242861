import React, { ForwardedRef, forwardRef, useContext, useState } from 'react';

import { HeroIcons, Input, Select, SelectValue } from '../../ComponentLibrary/src';
import { UserContext } from '../../context/User';
import { Roles } from './index.types';
import { validateFirstName, validateLastName } from './util';

const roleOptions = [
  {
    value: 'iccpTech',
    label: 'ICCP Tech',
  },
  {
    value: 'iccpEngineer',
    label: 'ICCP Engineer',
  },
  {
    value: 'automationTech',
    label: 'Automation Tech',
  },
  {
    value: 'automationEngineer',
    label: 'Automation Engineer',
  },
  {
    value: 'siteOperator',
    label: 'Site Operator',
  },
  {
    value: 'supervisor',
    label: 'Supervisor',
  },
  {
    value: 'superintendent',
    label: 'Superintendent',
  },
  {
    value: 'manager',
    label: 'Manager',
  },
  {
    value: 'engineer',
    label: 'Engineer',
  },
  {
    value: 'designEngineer',
    label: 'Design Engineer',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export interface Values {
  firstName: string;
  lastName: string;
  company: string;
  role: string;
}

interface Props {
  revealErrors: boolean;
  onNext: () => void;
  visible?: boolean;
}

const First = ({ revealErrors, onNext, visible = true }: Props, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
  const { user, setUser } = useContext(UserContext);
  const [formErrorsVisible, showFormErrors] = useState(false);
  const [otherRoleSelected, setOtherRoleSelected] = useState(false);

  const firstNameErrorMessage = validateFirstName(user.profile.firstName);
  const lastNameErrorMessage = validateLastName(user.profile.lastName);

  const onChangeFirstName = (value: string | number) => {
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        firstName: value.toString(),
      },
    });
  };

  const onChangeLastName = (value: string | number) => {
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        lastName: value.toString(),
      },
    });
  };

  const onChangeCompany = (value: string | number) => {
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        company: value.toString(),
      },
    });
  };

  const onChangeRole = (value: SelectValue) => {
    if (value === 'other') {
      setOtherRoleSelected(true);
      value = '';
    } else {
      setOtherRoleSelected(false);
    }
    setUser({
      ...user,
      profile: {
        ...user?.profile,
        role: value as Roles,
      },
    });
  };

  const onChangeManualRole = (value: string | number) => {
    if (value === '') {
      setOtherRoleSelected(false);
    } else {
      setUser({
        ...user,
        profile: {
          ...user?.profile,
          role: value as Roles,
        },
      });
    }
  };

  const handleNext = () => {
    if (firstNameErrorMessage || lastNameErrorMessage) {
      showFormErrors(true);
      return;
    }
    onNext();
  };

  return (
    <div className={`${visible ? '' : 'invisible'}`}>
      <Input
        ref={ref}
        onChangeValue={onChangeFirstName}
        leftIcon={HeroIcons.UserIcon}
        label="First Name"
        placeholder="First Name"
        value={user.profile.firstName}
        errorMessage={firstNameErrorMessage}
        revealErrorMessage={formErrorsVisible || revealErrors}
        onPressEnter={handleNext}
      />
      <Input
        onChangeValue={onChangeLastName}
        leftIcon={HeroIcons.UserIcon}
        label="Last Name"
        placeholder="Last Name"
        value={user.profile.lastName}
        errorMessage={lastNameErrorMessage}
        revealErrorMessage={formErrorsVisible || revealErrors}
        onPressEnter={handleNext}
      />
      <Input
        onChangeValue={onChangeCompany}
        leftIcon={HeroIcons.OfficeBuildingIcon}
        label="Company"
        placeholder="Company"
        value={user.profile.company}
        onPressEnter={handleNext}
      />
      <Select
        onChangeValue={onChangeRole}
        leftIcon={HeroIcons.BriefcaseIcon}
        label="Title/Role"
        value={otherRoleSelected ? 'other' : user.profile.role}
        options={roleOptions}
      />
      {otherRoleSelected && (
        <Input
          onChangeValue={onChangeManualRole}
          leftIcon={HeroIcons.BriefcaseIcon}
          label="Title/Role (Other)"
          placeholder="Title/Role"
          value={user.profile.role}
          onPressEnter={handleNext}
          autoFocus
        />
      )}
    </div>
  );
};

export default forwardRef<HTMLInputElement, Props>(First);
