import React, { useEffect } from 'react';

export default function AuthSuccess(): JSX.Element {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 500);
  }, []);

  return (
    <div>
      <h1>Authenticating...</h1>
    </div>
  );
}
