import React from 'react';

import { Form, FormSave, Input } from '../../ComponentLibrary/src';
import { EditUser, User } from '../../types';
import { isEmail } from '../../util';

interface ProfileProps {
  user: User;
  userDiff?: EditUser;
  formStatus?: 'dirty' | 'success' | 'loading';
  profileEnabled: boolean;
  newUserMode: boolean;
  loading?: boolean;
  handleSaveUser?: () => void;
  handleCancelSaveUser?: () => void;
  setUserDiff: (diff: EditUser) => void;
  setFormStatus?: (status?: 'dirty' | 'success' | 'loading') => void;
}

export default function Profile({
  user,
  userDiff,
  formStatus,
  profileEnabled,
  newUserMode,
  loading,
  handleSaveUser,
  handleCancelSaveUser,
  setUserDiff,
  setFormStatus,
}: ProfileProps): JSX.Element {
  const validateForm = (): boolean => {
    if (!isEmail(user?.email)) return false;
    // TODO: validate other properties
    return true;
  };

  const handleChangeFirstName = (firstName: string | number) => {
    setUserDiff({
      ...userDiff,
      profile: {
        ...userDiff?.profile,
        firstName: firstName.toString(),
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangeLastName = (lastName: string | number) => {
    setUserDiff({
      ...userDiff,
      profile: {
        ...userDiff?.profile,
        ...{
          lastName: lastName.toString(),
        },
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangePhone = (phone: string | number) => {
    setUserDiff({
      ...userDiff,
      profile: {
        ...userDiff?.profile,
        ...{
          phone: phone.toString(),
        },
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  const handleChangeMobile = (mobile: string | number) => {
    setUserDiff({
      ...userDiff,
      profile: {
        ...userDiff?.profile,
        ...{
          mobile: mobile.toString(),
        },
      },
    });
    if (setFormStatus) setFormStatus('dirty');
  };

  return (
    <Form
      preventDefault
      onSubmit={() => {
        if (validateForm() && handleSaveUser) handleSaveUser();
      }}
      className="flex flex-col gap-2 w-full p-4"
    >
      <div className="w-full">
        <div className="flex flex-row gap-2 flex-wrap sm:flex-nowrap w-full">
          <Input
            className="w-full"
            label="First Name"
            value={userDiff?.profile?.firstName ?? user?.profile?.firstName}
            onChangeValue={handleChangeFirstName}
            disabled={!profileEnabled}
          />
          <Input
            className="w-full"
            label="Last Name"
            value={userDiff?.profile?.lastName ?? user?.profile?.lastName}
            onChangeValue={handleChangeLastName}
            disabled={!profileEnabled}
          />
        </div>
        <div className="flex flex-row gap-2 flex-wrap sm:flex-nowrap w-full">
          <Input
            className="w-full"
            label="Phone"
            value={userDiff?.profile?.phone ?? user?.profile?.phone}
            onChangeValue={handleChangePhone}
            disabled={!profileEnabled}
          />
          <Input
            className="w-full"
            label="Mobile"
            value={userDiff?.profile?.mobile ?? user?.profile?.mobile}
            onChangeValue={handleChangeMobile}
            disabled={!profileEnabled}
          />
        </div>
      </div>
      {handleSaveUser && (
        <FormSave
          className="self-end"
          mode={newUserMode ? 'create' : 'edit'}
          onCancel={handleCancelSaveUser}
          status={loading ? 'loading' : formStatus}
        />
      )}
    </Form>
  );
}
