import React, { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';

import Api from '../adapters/accounts';
import { logout } from '../adapters/accounts/auth';
import { fetchCurrentUser } from '../adapters/accounts/users';
import DashboardApi from '../adapters/smartview';
import { Breadcrumb, Link, Loading, PageHeader, TopNav } from '../ComponentLibrary/src';
import { useMobile } from '../ComponentLibrary/src/util/hooks';
import { User } from '../types';
import { appendClassProps } from '../util';

interface Props {
  title: string;
  children: ReactNode;
  headerRight?: ReactElement;
  breadcrumbs?: Breadcrumb[];
  className?: string | string[];
}

const Page: FunctionComponent<Props> = ({ title, children, headerRight, breadcrumbs, className }: Props) => {
  const isMobile = useMobile();
  const user = Api.getUser();
  const dashboardApiDown = DashboardApi.isApiDown();
  const accountsApiDown = Api.isApiDown();

  useEffect(() => {
    if (!user) fetchCurrentUser().then((info: User) => Api.setUser(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user && !dashboardApiDown && !accountsApiDown) return <Loading />;

  const userNavInfo = {
    email: user?.email ?? '',
    profile: {
      firstName: user?.profile?.firstName ?? '',
      lastName: user?.profile?.lastName ?? '',
    },
  };

  return (
    <div
      className={`flex flex-col w-full responsive-h-screen ${isMobile ? 'bg-white' : 'bg-blue-50'}${appendClassProps(
        className,
      )}`}
    >
      <div className={isMobile ? 'fixed top-0 left-0 right-0 z-900' : ''}>
        <TopNav
          onLogout={logout}
          user={userNavInfo}
          userAccountUrl={process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL ?? 'https://accounts.qnergy.com'}
          swVer={process.env.REACT_APP_RELEASE_VERSION}
          externalAppLink={
            <div className="flex items-center justify-center">
              <Link
                href={process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}
                className="h-full p-2 text-lg"
              >
                Go to Dashboard
              </Link>
            </div>
          }
          phone={process.env.REACT_APP_SUPPORT_TEL}
          termsHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/terms`}
          privacyHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/privacy`}
        />
      </div>
      <div className={`h-full flex flex-col gap-4 p-4 overflow-y-auto${isMobile ? ' mt-12' : ''}`}>
        <PageHeader title={title} rightComponent={headerRight} breadcrumbs={breadcrumbs} />
        {children}
        {/* <PageFooter version={`v${packageJson.version}`} className="border-t border-blue-800" /> */}
      </div>
    </div>
  );
};

export default Page;
