import { KeyIcon, UserIcon, UsersIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Api from '../../adapters/accounts';
import { deleteUser } from '../../adapters/accounts/users';
import DashboardApi from '../../adapters/smartview';
import { Avatar, Button, Card, HeroIcons, Input, KeyValue, Tab, Variant } from '../../ComponentLibrary/src';
import Page from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { UserContext, useUserContextValue } from '../../context/User';
import { Distributor, User, UserState } from '../../types';
import { isEmail } from '../../util';
import { PERMISSIONS } from '../../util/constants';
import { RoleNames } from '../../util/permissions';
import Access from './Access';
import Profile from './Profile';
import Roles from './Roles';

const iconClass = 'h-6 w-6 text-blue-800';

function Account(): JSX.Element {
  const navigate = useNavigate();
  const { email: emailUrlParam } = useParams<{ email: string }>();
  const [newUserMode, setNewUserMode] = useState(false);
  const {
    getCurrentUser,
    user,
    saveUser,
    userDiff,
    setUserDiff,
    getUser,
    updateInvite,
    requestReset,
    // requestChangeEmail,
    enableUser,
    disableUser,
  } = useContext(UserContext);
  const { hasPermissions } = useContext(AuthContext);
  const { t } = useTranslation();
  const [newEmail] = useState('');
  const [formStatus, setFormStatus] = useState<'dirty' | 'success' | 'loading' | undefined>();
  const [loading, setLoading] = useState(false);
  const [distributorErrorMessage, setDistributorErrorMessage] = useState<string | undefined>('');
  const [isDistributor, setIsDistributor] = useState(
    (userDiff?.roles ?? user?.roles ?? []).includes(RoleNames.distributor),
  );
  const [newUserDistributor, setNewUserDistributor] = useState<Distributor | null>();
  const emailParam = emailUrlParam?.toLowerCase() ?? '';
  Api.useRenderOnUserChange();
  DashboardApi.useRenderOnUserChange();

  const currentUser = Api.getUser();
  const email = emailParam === 'current' ? currentUser?.email : emailParam;

  useEffect(() => {
    if (userDiff && Object.keys(userDiff).length) {
      setFormStatus('dirty');
    } else {
      setFormStatus(undefined);
    }
  }, [userDiff]);

  useEffect(() => {
    if (currentUser?.email === emailParam) navigate('/users/current');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.email]);

  const accountTabs = useMemo(
    () =>
      [
        {
          label: 'Profile',
          mobileIcon: <UserIcon className={iconClass} />,
        },
        {
          label: 'Roles',
          mobileIcon: <UsersIcon className={iconClass} />,
        },
        {
          label: 'Access',
          mobileIcon: <KeyIcon className={iconClass} />,
        },
      ].filter((tab) => {
        if (tab.label === 'Roles') {
          if (emailUrlParam === 'current' || !hasPermissions(PERMISSIONS.qnergy.users.updateRoles)) {
            return false;
          }
        }
        if (user?.userState === 'pending' && tab.label === 'Profile') return false;
        if ((isDistributor || emailUrlParam === 'current') && tab.label === 'Access') return false;
        return true;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailUrlParam, user?.userState, isDistributor, currentUser],
  );

  const updateUserDetails = async (): Promise<User | void> => {
    if (emailParam === 'current') {
      return getCurrentUser().catch(() => navigate('/users'));
    } else if (isEmail(emailParam)) {
      return getUser(emailParam)
        .then((user) => setIsDistributor(user?.roles?.includes(RoleNames.distributor) ?? false))
        .catch(() => navigate('/users'));
    } else if (emailParam === 'new') {
      setNewUserMode(true);
    } else {
      navigate('/users');
    }
  };

  useEffect(() => {
    updateUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelSaveUser = async () => {
    await updateUserDetails();
    setFormStatus(undefined);
    setUserDiff();
  };

  // const handleChangeEmail = (email: string | number) => {
  //   if ((newUserMode && user?.email) || email) {
  //     setFormStatus('dirty');
  //   }
  //   if (newUserMode) {
  //     setUser({
  //       ...user,
  //       email: email.toString(),
  //     });
  //   } else {
  //     setNewEmail(email.toString());
  //   }
  // };

  const handleResendInvite = () => {
    if (
      window.confirm('This will send an email to the user with instructions to set up their account. Are you sure?')
    ) {
      if (email) updateInvite(email);
    }
  };

  const handleSelectRole = (value: string | number, reset?: boolean) => {
    const roles = reset ? [] : [...new Set(userDiff?.roles ?? user?.roles)];
    if (!roles.includes(value as string)) roles.push(value as string);
    setUserDiff({
      ...userDiff,
      roles,
    });
    if (!reset) setFormStatus('dirty');
  };

  const handleRemoveRole = (role: string) => {
    const roles = [...new Set(userDiff?.roles ?? user?.roles)].filter((r) => r !== role);
    setUserDiff({
      ...userDiff,
      roles,
    });
    setFormStatus('dirty');
  };

  const validateUser = () => {
    if (newUserMode) {
      if (formStatus === 'dirty') return user?.email && isEmail(user?.email);
    } else {
      if (formStatus === 'dirty') return user?.email && isEmail(user?.email);
    }
    return true;
  };

  const revealEmailErrorMessage = !validateUser();

  const { profileEnabled } = {
    profileEnabled: emailParam === 'current' || newUserMode || hasPermissions(PERMISSIONS.qnergy.users.update),
  };

  const handleChangePassword = async () => {
    if (
      window.confirm('This will send an email to the user with instructions to reset their password. Are you sure?')
    ) {
      try {
        await requestReset(user?.email);
        alert('password reset email sent');
      } catch (err) {
        alert('password reset failed');
      }
    }
  };

  const handleSaveUser = async () => {
    if (!validateUser()) throw new Error('form data is not valid');
    if (newUserMode) {
      if (!window.confirm('This will send an email invitation to the email address provided. Are you sure?')) return;
      setLoading(true);
      if (email)
        updateInvite(email)
          .then(() => navigate(`/users/${user?.email}`))
          .then(() => {
            setLoading(false);
            setFormStatus('success');
            setNewUserMode(false);
          })
          .catch(() => {
            setFormStatus(undefined);
            alert('error sending email');
          });
    } /* else if (newEmail) { // temporarily disabled
      if (
        window.confirm(
          'This will send an email to the user with instructions to change the email address. Are you sure?',
        )
      ) {
        setLoading(true);
        requestChangeEmail(user?.email, newEmail)
          .then(() => {
            setLoading(false);
            setFormStatus('success');
          })
          .then(() => {
            alert('email sent, please verify by clicking the link in the email');
          })
          .catch(() => {
            setFormStatus(undefined);
            alert('error sending email');
          });
      }
    }*/ else if (user?.email) {
      if (isDistributor && !newUserDistributor) {
        setDistributorErrorMessage(t('roles:must_select_distributor'));
      } else {
        const isSelf = emailUrlParam === 'current';
        if (setFormStatus) setFormStatus('loading');

        let updateSuccess = true;
        const onFail = () => {
          updateSuccess = false;
        };
        if (userDiff) {
          await saveUser(onFail);
        }
        if (updateSuccess) {
          toast('Success: User updated', { type: 'success' });
          setFormStatus('success');
          if (!isSelf && newUserMode) {
            navigate(`/users/${user?.email}`);
          } else {
            await updateUserDetails();
          }
          setUserDiff();
          setNewUserMode(false);
        } else {
          handleCancelSaveUser();
        }
        setLoading(false);
      }
    }
  };

  const handleDisableUser = () => {
    disableUser(user?.email).then(() => updateUserDetails());
  };

  const handleEnableUser = () => {
    enableUser(user?.email).then(() => updateUserDetails());
  };

  const handleDeleteUser = () => {
    if (window.confirm(`Are you sure you want to delete user with email ${user?.email}? This cannot be undone.`)) {
      deleteUser(user?.email).then(() => updateUserDetails());
    }
  };

  return (
    <Page
      title="Account"
      breadcrumbs={
        emailUrlParam && hasPermissions(PERMISSIONS.qnergy.users.read)
          ? [
              { text: 'Users', href: '/users' },
              { text: newUserMode ? emailUrlParam?.charAt(0).toUpperCase() + emailUrlParam.slice(1) : user?.email },
            ]
          : undefined
      }
    >
      <div className="flex flex-row gap-4 flex-wrap md:flex-nowrap">
        <Card className="w-full md:max-w-xs flex flex-col gap-4 h-max">
          <div className="w-full flex flex-row justify-center">
            <Avatar
              uniqueId={user?.email}
              className="w-full max-w-lg"
              initials={`${user?.profile?.firstName?.charAt(0).toUpperCase() ?? ''}${
                user?.profile?.lastName?.charAt(0).toUpperCase() ?? ''
              }`}
            />
          </div>
          <Button icon={HeroIcons.LockClosedIcon} onClick={handleChangePassword} className="w-full">
            Change Password
          </Button>
          <Input
            label="Email"
            tooltip="Email address used for login and notifications"
            // disabled={!profileEnabled} // disabled until implemented on the backend
            disabled
            value={newEmail || user?.email}
            // onChangeValue={handleChangeEmail}
            revealErrorMessage={revealEmailErrorMessage}
            errorMessage={!validateUser() ? 'must provide a valid email' : ''}
          />
          {user?.userState && (
            <KeyValue
              label="User State"
              value={`${user?.userState?.charAt(0).toUpperCase()}${user?.userState?.slice(1)}`}
              tooltip="Pending: hasn't accepted invitation, Disabled: account has been disabled, Active: account is active"
            />
          )}
          <KeyValue
            label="Joined"
            value={DateTime.fromISO(user?.created as string).toLocaleString(DateTime.DATE_MED)}
            tooltip="When the user created a Qnergy account."
          ></KeyValue>
          <KeyValue
            label="Last Login"
            value={
              user?.lastLogin
                ? DateTime.fromISO(user?.lastLogin as string).toLocaleString(DateTime.DATETIME_MED)
                : 'never'
            }
            tooltip="The last time the user logged in using their Qnergy account."
          ></KeyValue>
          {!newUserMode && user?.userState === UserState.pending && (
            <Button
              icon={HeroIcons.MailIcon}
              className="w-max"
              variant={Variant.secondary}
              onClick={handleResendInvite}
            >
              Resend Invite
            </Button>
          )}
          {emailParam !== 'current' && user?.userState === UserState.active && (
            <Button className="w-full" variant={Variant.secondary} onClick={handleDisableUser}>
              Deactivate
            </Button>
          )}
          {emailParam !== 'current' && user?.userState === UserState.disabled && (
            <Button className="w-full" onClick={handleEnableUser}>
              Activate
            </Button>
          )}
          {hasPermissions(PERMISSIONS.qnergy.users.delete) && emailParam !== 'new' && emailParam !== 'current' && (
            <Button className="w-full" onClick={handleDeleteUser} variant={Variant.danger}>
              Delete User
            </Button>
          )}
        </Card>
        <Tab
          className="w-full md:w-3/4 flex flex-col shadow h-max"
          tabs={accountTabs}
          tabContents={accountTabs.map((tab) => {
            if (tab.label === 'Profile') {
              return (
                <Profile
                  key={tab.label}
                  user={user}
                  userDiff={userDiff}
                  handleSaveUser={handleSaveUser}
                  handleCancelSaveUser={handleCancelSaveUser}
                  setUserDiff={(diff) => {
                    setUserDiff({
                      ...userDiff,
                      ...diff,
                    });
                  }}
                  setFormStatus={setFormStatus}
                  formStatus={formStatus}
                  profileEnabled={profileEnabled}
                  newUserMode={newUserMode}
                  loading={loading}
                />
              );
            } else if (tab.label === 'Roles') {
              return (
                <Roles
                  key={tab.label}
                  onSaveUser={handleSaveUser}
                  formStatus={formStatus}
                  onCancelSaveUser={handleCancelSaveUser}
                  onRemoveRole={handleRemoveRole}
                  onSelectRole={handleSelectRole}
                  newUserMode={newUserMode}
                  isDistributor={isDistributor}
                  setNewUserDistributor={setNewUserDistributor}
                  newUserDistributor={newUserDistributor}
                  distributorErrorMessage={distributorErrorMessage}
                  setIsDistributor={setIsDistributor}
                />
              );
            } else if (tab.label === 'Access') {
              return (
                <Access
                  key={tab.label}
                  newUserMode={newUserMode}
                  setFormStatus={setFormStatus}
                  formStatus={formStatus}
                  onSaveUser={handleSaveUser}
                  onCancelSaveUser={handleCancelSaveUser}
                />
              );
            } else {
              return <></>;
            }
          })}
        />
      </div>
    </Page>
  );
}

export default function AccountContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <Account />
    </UserContext.Provider>
  );
}
