import { SMARTVIEW_API_URL } from '../../util/constants';

export let token: string | undefined;

export function loadApiUsed(): 'default' | 'secondary' {
  return (localStorage.getItem('apiUsed') as 'default' | 'secondary') ?? 'default';
}

export function getApiUrl(): string {
  const apiUsed = loadApiUsed();
  let apiUrl = process.env.REACT_APP_SMARTVIEW_API_URL || SMARTVIEW_API_URL;

  if (process.env.REACT_APP_ENV === 'staging') {
    apiUrl =
      apiUsed === 'secondary'
        ? process.env.REACT_APP_SMARTVIEW_STAGING_API_URL ?? ''
        : process.env.REACT_APP_SMARTVIEW_API_URL ?? '';
  }
  return apiUrl;
}

export const getAuthorizationCode = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const apiUrl = getApiUrl();
    const authWindow = window.open(
      `${apiUrl}/login?redirectUrl=${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/authSuccess`,
      undefined,
      'width=500,height=500',
    );
    // poll to find out the state of the window, since events are not reliable
    const timer = setInterval(() => {
      if (authWindow) {
        if (authWindow.closed) {
          clearInterval(timer);
          reject();
        } else if (authWindow.document.URL === `${process.env.REACT_APP_ACCOUNTS_FRONTEND_URL}/authSuccess`) {
          clearInterval(timer);
          resolve();
        }
      }
    }, 1000);
  });
};
