import { ExternalLinkIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Api from '../../adapters/accounts';
import { fetchDistributors, fetchUserAccess } from '../../adapters/smartview/endpoints';
import { AsyncSelect, Checkbox, Form, FormSave, Link, Loading, Text } from '../../ComponentLibrary/src';
import { AuthContext } from '../../context/Auth';
import { UserContext } from '../../context/User';
import { Distributor, Role } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import { RoleNames } from '../../util/permissions';
import PermissionList from './components/PermissionList';
import SettableRoles from './components/SettableRoles';

interface Props {
  formStatus?: 'dirty' | 'success' | 'loading' | undefined;
  newUserMode: boolean;
  distributorErrorMessage?: string;
  isDistributor: boolean;
  newUserDistributor?: Distributor | null;
  setIsDistributor: (isDistributor: boolean) => void;
  setNewUserDistributor: (userDistributor?: Distributor | null) => void;
  onCancelSaveUser?: () => void;
  onSaveUser?: () => void;
  onRemoveRole: (role: RoleNames) => void;
  onSelectRole: (role: RoleNames, reset?: boolean) => void;
}

export default function RolesComponent({
  formStatus,
  newUserMode,
  isDistributor,
  newUserDistributor,
  distributorErrorMessage,
  setIsDistributor,
  setNewUserDistributor,
  onSaveUser,
  onCancelSaveUser,
  onRemoveRole,
  onSelectRole,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('roles');
  const { user, setUser, userDiff, setUserDiff } = useContext(UserContext);
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { email: emailUrlParam } = useParams<{ email: string }>();

  const syncUserDistributor = useCallback(() => {
    if (newUserMode || !user.email) return;
    return fetchUserAccess(user.email)
      .then((newUser) => {
        if (newUser.distributor) {
          setUser({ ...user, distributor: newUser.distributor });
          setNewUserDistributor(newUser.distributor);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [newUserMode, user, setUser, setNewUserDistributor]);

  const enforcePermissions = useCallback(() => {
    if (hasPermissions(PERMISSIONS.qnergy.users.updateRoles)) return;

    toast(t('No Access'), { type: toast.TYPE.ERROR });
    navigate(`/users/${emailUrlParam}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailUrlParam]);

  useEffect(() => {
    syncUserDistributor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDiff]);

  useEffect(() => {
    enforcePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeIsDistributor = (checked: boolean) => {
    setIsDistributor(checked);
    if (checked) {
      if (onSelectRole) onSelectRole(RoleNames.distributor, true);
      syncUserDistributor();
      setNewUserDistributor();
    } else {
      const newDiff = {
        ...userDiff,
        roles: (userDiff?.roles ?? user?.roles)?.filter((role) => role !== RoleNames.distributor),
      };
      if (user.distributor) newDiff.distributor = null;
      if (!newUserMode) setUserDiff(newDiff);
      setNewUserDistributor(user.distributor ? null : undefined);
      if (onRemoveRole) onRemoveRole(RoleNames.distributor);
    }
  };

  const handleChangeRoles = (checked: boolean, role: RoleNames) => {
    if (checked) {
      if (onSelectRole) onSelectRole(role);
    } else {
      if (onRemoveRole) onRemoveRole(role);
    }
  };

  const { settableRoles } = Api.getUser();

  const canSetDistributor = settableRoles?.some((role) => role.name === RoleNames.distributor);
  return loading ? (
    <div className="h-full flex justify-center items-center">
      <Loading type="medium" />
    </div>
  ) : (
    <Form
      preventDefault
      onSubmit={async () => {
        if ((userDiff?.roles ?? (user?.roles || [])).length) {
          if (onSaveUser) onSaveUser();
        } else {
          toast(t('must_select_role'), {
            type: 'error',
          });
        }
      }}
      className="w-full p-4 flex flex-col"
    >
      {canSetDistributor && (
        <div className="w-full flex flex-row gap-2 items-center mb-4">
          <Checkbox
            disabled={!canSetDistributor}
            onChangeValue={handleChangeIsDistributor}
            checked={isDistributor}
            hideErrorSection
            label="User is a distributor"
          />
        </div>
      )}

      {!isDistributor ? (
        <SettableRoles
          settableRoles={settableRoles ?? []}
          assignedRoles={userDiff?.roles ?? user?.roles}
          handleChangeRoles={handleChangeRoles}
        />
      ) : (
        <div className="flex flex-col gap-2">
          <AsyncSelect
            disabled={!canSetDistributor}
            onLoadOptions={(searchTerm) =>
              fetchDistributors({ searchTerm }).then((distributors) =>
                distributors.map((d) => ({
                  value: d._id,
                  label: d.label ?? d.name,
                })),
              )
            }
            className="w-56"
            value={
              (userDiff?.distributor ?? user.distributor) && userDiff?.distributor !== null
                ? {
                    value: newUserDistributor?._id,
                    label: newUserDistributor?.name as string,
                  }
                : undefined
            }
            onChange={(option) => {
              if (option?.value && option?.label) {
                setUserDiff({
                  ...userDiff,
                  distributor: option?.value.toString(),
                });

                setNewUserDistributor({
                  _id: option.value as string,
                  name: option.label as string,
                });
              } else if (option === null) {
                setUserDiff({
                  ...userDiff,
                  distributor: null,
                });
                setNewUserDistributor(null);
              }
            }}
            clearable
            revealErrorMessage
            errorMessage={distributorErrorMessage}
            menuPlacement="bottom"
            placeholder="Select a distributor"
          />
          <Text inline>User is a distributor, org access is set on the distributor. </Text>
          <Link
            newTab
            href={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/distributors`}
            className="flex flex-row items-center w-full gap-2"
          >
            <span>Go to Distributor </span>
            <ExternalLinkIcon className="h-4 w-4" />
          </Link>
          {settableRoles
            ?.filter((role: Role) => {
              return role.name == RoleNames.distributor;
            })
            .map((role: Role) => (
              <PermissionList role={role} key={`permission-${role.name}`} />
            ))}
        </div>
      )}
      {!newUserMode && (
        <FormSave
          className="self-end mt-10"
          status={formStatus}
          onCancel={onCancelSaveUser}
          mode={newUserMode ? 'create' : 'edit'}
        />
      )}
    </Form>
  );
}
