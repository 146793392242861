import Slider from 'infinite-react-carousel';
import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { Loading } from '../ComponentLibrary/src';
import AuthWallBg from '../components/Bg';
import Container from '../components/Container';
import Content from '../components/Content';
import Footer from '../components/Footer';
import Header from '../components/Header';
import First from '../components/SignupStages/First';
import { Stages } from '../components/SignupStages/index.types';
import Second from '../components/SignupStages/Second';
import Third, { Values as StageThreeValues } from '../components/SignupStages/Third';
import { validateStage } from '../components/SignupStages/util';
import { UserContext, useUserContextValue } from '../context/User';
import { useQuery } from '../hooks';
import { UserState } from '../types';
import { isEmail } from '../util';

const Signup: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>(null);
  const navigate = useNavigate();
  const query = useQuery();
  const { signup, validateSignupToken, user } = useContext(UserContext);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const mobileRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState(0);
  const [signupToken, setSignupToken] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [revealErrors, setRevealErrors] = useState(false);
  const [signupDisabled, setSignupDisabled] = useState(true);
  const [transitioning, setTransitioning] = useState(false);

  useEffect(() => {
    const emailParam = query.get('email');
    const signupTokenParam = query.get('signupToken');
    if (!emailParam) {
      alert('email address not found, please use the invitation link');
      return navigate('/');
    }
    if (!signupTokenParam) {
      alert('signup token missing, please use the invitation link');
      return navigate('/');
    }
    if (!isEmail(emailParam)) {
      alert('invalid email address, please contact the person who invited you');
      return navigate('/');
    }
    validateSignupToken(signupTokenParam)
      .then(() => {
        setSignupToken(signupTokenParam || '');
      })
      .catch(() => {
        setSignupToken('Invalid');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStageThree = ({ confirmPassword, termsAccepted }: StageThreeValues) => {
    setConfirmPassword(confirmPassword);
    setTermsAccepted(termsAccepted);
  };

  const onEntered = (step: number) => {
    switch (step) {
      case Stages.name:
        firstNameRef?.current?.focus();
        break;
      case Stages.phone:
        mobileRef?.current?.focus();
        break;
      case Stages.password:
        passwordRef?.current?.focus();
        break;
    }
  };

  const onBack = useCallback(() => {
    setTransitioning(true);
    if (sliderRef.current) sliderRef.current.slickPrev();
    setStep(step - 1);
  }, [step]);

  const onNext = useCallback(() => {
    if (
      !validateStage(step, {
        ...user.profile,
        password: user.password,
        email: user.email,
        confirmPassword,
        termsAccepted,
      })
    ) {
      setRevealErrors(true);
      return;
    }
    setRevealErrors(false);

    setTransitioning(true);
    if (sliderRef.current) sliderRef.current.slickNext();
    setStep(step + 1);
  }, [step, confirmPassword, user, termsAccepted]);

  const onSkip = useCallback(() => {
    setTransitioning(true);
    if (sliderRef.current) sliderRef.current.slickNext();
    setStep(step + 1);
  }, [step]);

  const onSubmit = useCallback(() => {
    if (
      !validateStage(step, {
        ...user.profile,
        password: user.password,
        email: user.email,
        confirmPassword,
        termsAccepted,
      })
    ) {
      setRevealErrors(true);
      return;
    }
    setRevealErrors(false);
    signup({
      ...user,
      signupToken,
      userState: UserState.active,
      created: new Date(),
    }).then((result) => {
      if (result) {
        window.location.href = process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com';
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, step, signupToken, confirmPassword, termsAccepted]);

  if (!signupToken) return <Loading />;

  if (signupToken === 'Invalid')
    return (
      <AuthWallBg>
        <Header title="Invalid or expired signup link" color="red" />
      </AuthWallBg>
    );

  return (
    <AuthWallBg>
      <Container>
        <Header title="Sign Up" />
        <Content className="overflow-x-hidden">
          <Slider
            ref={sliderRef}
            className="w-full"
            arrows={false}
            swipe={false}
            centerMode={true}
            centerPadding={16}
            shift={64}
            duration={100}
            accessibility={false}
            afterChange={(index: number) => {
              onEntered(index);
              setTransitioning(false);
            }}
          >
            <First
              visible={step === Stages.name || transitioning}
              ref={firstNameRef}
              revealErrors={revealErrors}
              onNext={onNext}
            />
            <Second visible={step === Stages.phone || transitioning} ref={mobileRef} onNext={onNext} />
            <Third
              visible={step === Stages.password || transitioning}
              ref={passwordRef}
              revealErrors={revealErrors}
              onChangeStage={onChangeStageThree}
              onNext={onSubmit}
              setSignupDisabled={setSignupDisabled}
            />
          </Slider>
        </Content>
        <Footer
          showSignup={step === Stages.password}
          showBack={step !== Stages.name}
          allowSkip={step === Stages.phone}
          signupDisabled={signupDisabled}
          onSubmit={onSubmit}
          onNext={onNext}
          onBack={onBack}
          onSkip={onSkip}
        />
      </Container>
    </AuthWallBg>
  );
};

export default function SignupPasswordContainer(): JSX.Element {
  const userContextValue = useUserContextValue();

  return (
    <UserContext.Provider value={userContextValue}>
      <Signup />
    </UserContext.Provider>
  );
}
