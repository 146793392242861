import React, { FunctionComponent } from 'react';

import packageJson from '../../package.json';
import { Button, Link, Text, Variant } from '../ComponentLibrary/src';

interface Props {
  showSignup: boolean;
  showBack: boolean;
  allowSkip: boolean;
  signupDisabled: boolean;
  onSubmit: () => void;
  onNext: () => void;
  onBack: () => void;
  onSkip: () => void;
}

const Footer: FunctionComponent<Props> = ({
  showSignup = true,
  showBack,
  allowSkip,
  signupDisabled,
  onSubmit,
  onNext,
  onBack,
  onSkip,
}: Props) => {
  return (
    <div className="w-full flex flex-col items-center mt-2 gap-4">
      <Button
        variant={Variant.primary}
        size="large"
        onClick={showSignup ? onSubmit : onNext}
        containerClassName="w-full"
        className="w-full transition-color duration-500"
        disabled={showSignup && signupDisabled}
      >
        {showSignup ? 'Sign Up' : 'Next'}
      </Button>
      <div className={`w-full flex flex-row justify-around items-center flex-wrap`}>
        <Link
          disabled={!showBack}
          className={`text-lg transition-opacity ${showBack ? '' : 'opacity-0'}`}
          onClick={onBack}
        >
          Back
        </Link>
        <Link
          disabled={!allowSkip}
          className={`text-lg transition-opacity ${allowSkip ? '' : 'opacity-0'}`}
          onClick={onSkip}
        >
          Skip this step
        </Link>
      </div>
      <div className={`w-full flex flex-row justify-center gap-2 items-center flex-wrap mt-4`}>
        <Text className="font-thin">v{packageJson.version || 'ver?'}</Text>
        <Text className="font-thin">© {new Date().getFullYear()} Qnergy</Text>
      </div>
    </div>
  );
};

export default Footer;
